import { Button, Card, CardProps, Flex, Text } from '@chakra-ui/react';
import { CoinIcon } from '../../../../assets/customIcons/CoinIcon';
import NewRechargeModal from '../NewRechargeModal';
import { LuPlusCircle, LuUpload } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';

const RechargeEmptyState = (props: CardProps) => {
  const navigate = useNavigate();
  return (
    <Card
      width="100%"
      gap="6"
      height="calc(100vh - 273px)"
      direction="column"
      alignItems="center"
      justifyContent="center"
      backgroundColor="gray.50"
      minHeight="250px"
      padding={8}
      {...props}
    >
      <CoinIcon boxSize="56px" color="primary.primary" strokeWidth="3" />
      <Flex flexDirection="column" alignItems="center" gap="3">
        <Text
          fontSize="32px"
          lineHeight="130%"
          fontWeight={700}
          color="colors.black"
          fontFamily="sora"
        >
          Já Raiô!
        </Text>
        <Text
          fontSize="32px"
          fontFamily={'Inter'}
          fontWeight={400}
          lineHeight="130%"
          color="colors.black"
        >
          Você ainda não possui nenhuma recarga
        </Text>
      </Flex>
      <Flex gap={4} alignItems="start" justifyContent="flex-end">
        <Button
          leftIcon={<LuUpload size="20px" />}
          variant="outline"
          marginBottom="auto"
          width="fit-content"
          onClick={() => navigate('/recargas/preparar-planilha')}
        >
          Fazer recarga via planilha
        </Button>
        <NewRechargeModal>
          <Button leftIcon={<LuPlusCircle size="20px" />} w="400px">
            Fazer recarga selecionando colaboradores
          </Button>
        </NewRechargeModal>
      </Flex>
    </Card>
  );
};

export default RechargeEmptyState;
