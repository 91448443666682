import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Link,
  Text,
  forwardRef,
} from '@chakra-ui/react';
import { H2 } from '../../../components/Typography';
import { FiDownload } from 'react-icons/fi';
import { format } from 'date-fns';
import {
  DocumentTypeEnum,
  RechargePaymentMethod,
  EditRechargeResultResponse,
} from '../../../types/recharge';
import { baseDownloadUrl } from '../../../constants/general';
import InvoiceDownloadButton from '../../NewRechargePage/components/InvoiceDownloadButton';
import EditedRechargeInvoiceDownloadButton from './EditedRechargeInvoiceDownloadButton';

export const DownloadButton = forwardRef<ButtonProps, 'button'>(
  (props, ref) => {
    return (
      <Button
        ref={ref}
        leftIcon={<FiDownload />}
        paddingY={2}
        paddingX={4}
        background={'gray.100'}
        variant={'ghost'}
        fontSize={'16px'}
        fontWeight={600}
        _disabled={{
          color: 'gray.300',
          _hover: {
            background: 'gray.100',
          },
        }}
        {...props}
      />
    );
  }
);

type EditedRechargeDocsProps = {
  rechargeInfo: EditRechargeResultResponse;
  isPluralRecharge: boolean;
  statusIsProcessed: boolean;
};

const EditedRechargeDocs = ({
  rechargeInfo,
  isPluralRecharge,
  statusIsProcessed,
}: EditedRechargeDocsProps) => {
  const paymentMethodDueDate = format(
    new Date(rechargeInfo.invoice?.dueDate || new Date()),
    'dd/MM/yyyy'
  );
  const isRechargePaidWithAccountBalance =
    rechargeInfo.paymentMethod === RechargePaymentMethod.ACCOUNT_BALANCE;

  return (
    <Box p={8} bg="white" borderRadius="8px" width="100%">
      <H2>Documentos da recarga</H2>
      {!statusIsProcessed && (
        <Text mt={9}>
          Se quiser, você pode fazer{' '}
          <Text as="span" fontWeight={600}>
            download{' '}
          </Text>
          dos documentos da recarga.
          <Text marginTop={4} as="span" display="block">
            Eles também serão enviados{' '}
            <Text as="span" fontWeight={600}>
              para você e para o time financeiro por e-mail.
            </Text>
          </Text>
        </Text>
      )}
      <Flex flexDir={'column'} gap={4} marginTop={8}>
        {!isRechargePaidWithAccountBalance && !statusIsProcessed && (
          <DownloadButton
            isDisabled={!rechargeInfo.rechargeId}
            maxWidth="fit-content"
            as={Link}
            href={`${baseDownloadUrl}?rechargeId=${rechargeInfo.rechargeId}&documentType=${DocumentTypeEnum.PAYMENT}`}
            download={`Recarga Raiô - Vencimento ${paymentMethodDueDate}.pdf`}
            height="56px"
          >
            {rechargeInfo.paymentMethod === 'BOLETO' && 'Boleto'}
            {rechargeInfo.paymentMethod === 'PIX' && 'Código QR'}
          </DownloadButton>
        )}
        <DownloadButton
          as={Link}
          height="56px"
          isDisabled={!rechargeInfo.rechargeId}
          href={`${baseDownloadUrl}?rechargeId=${rechargeInfo.rechargeId}&documentType=${DocumentTypeEnum.REPORT}`}
        >
          Relatório da recarga
        </DownloadButton>

        <EditedRechargeInvoiceDownloadButton
          isPluralRecharge={isPluralRecharge}
          rechargeId={rechargeInfo.rechargeId}
        />
      </Flex>
    </Box>
  );
};

export default EditedRechargeDocs;
