import {
  Box,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { FiFileText } from 'react-icons/fi';
import { RechargePollingResponse } from '../../../../types/recharge';
import { format } from 'date-fns';
import { formatCurrencyInCents } from '../../../../utils/formatters/formatCurrency';
import { formatCNPJ } from '../../../../utils/formatters/formatCNPJ';
import { IMaskInput } from 'react-imask';
import { globalCustomerConfigStore } from '../../../../stores/globalCustomerConfigStore';
import CopyButton from '../../../../components/CopyButton';
import RechargeInfoPageTitle from '../RechargeInfoPageTitle';

const RechargeInfo = () => {
  return (
    <Flex marginBottom={6}>
      <Text>
        Escaneie a imagem do{' '}
        <Text as="span" fontWeight={600}>
          código de barras
        </Text>{' '}
        no boleto abaixo com o aplicativo do seu banco para realizar o pagamento
        da recarga:
      </Text>
    </Flex>
  );
};

type RechargeInfoBoletoProps = {
  rechargeInfo: RechargePollingResponse['rechargeInfo'] | undefined;
};

const RechargeInfoBoleto = ({ rechargeInfo }: RechargeInfoBoletoProps) => {
  const invoiceDigitableLine = rechargeInfo?.invoice?.digitableLine;

  const { globalCustomerConfig } = globalCustomerConfigStore();

  const paymentBeneficiary = globalCustomerConfig?.paymentBeneficiary;

  return (
    <Box padding={8} width="100%" background="white" borderRadius="8px">
      <RechargeInfoPageTitle marginBottom={8} isLoaded={!!rechargeInfo} />

      <RechargeInfo />
      <Skeleton minWidth="100%" minHeight="526px" isLoaded={!!rechargeInfo}>
        {rechargeInfo?.invoice?.pdf && (
          <object
            title="boleto"
            data={`data:application/pdf;base64,${rechargeInfo.invoice.pdf}#zoom=100;`}
            type="application/pdf"
            width="100%"
            height="526px"
          />
        )}
      </Skeleton>
      <Box mt="32px" mb="16px">
        <Flex mb="16px">
          <Text>
            Se preferir, copie o{' '}
            <Text as="span" fontWeight={600}>
              código do boleto
            </Text>{' '}
            para pagamento:
          </Text>
        </Flex>
        <Skeleton isLoaded={!!invoiceDigitableLine}>
          <InputGroup marginBottom="32px">
            <InputRightElement
              w="54px"
              h="54px"
              top="0.7px"
              right="29px"
              cursor="pointer"
            >
              <CopyButton copyText={invoiceDigitableLine || ''} />
            </InputRightElement>
            <Input
              defaultValue={invoiceDigitableLine}
              as={IMaskInput}
              mask="00000.00000 00000.000000 00000.000000 0 00000000000000"
              pr="105px"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            />
          </InputGroup>
        </Skeleton>
        <Skeleton width="fit-content" isLoaded={!!rechargeInfo}>
          <Text fontSize="32px" fontWeight={600} mb="32px" width="fit-content">
            {rechargeInfo && formatCurrencyInCents(rechargeInfo.amount)}
          </Text>
        </Skeleton>
        <Text fontSize={'16px'} fontWeight={'600'} mb="8px">
          Dados do beneficiário
        </Text>
        <InputGroup mb="24px">
          <InputLeftElement
            w="54px"
            h="54px"
            top="0.7px"
            cursor="pointer"
            mb="32px"
          >
            <Flex alignItems="center" color="primary.primary">
              <Icon as={FiFileText} boxSize="20px" />
            </Flex>
          </InputLeftElement>
          <Flex
            alignItems="center"
            pl="32px"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            bg="gray.100"
            borderWidth="1px"
            borderColor="gray.300"
            borderRadius="md"
            minHeight="54px"
            mb="32px"
            flexGrow={1}
          >
            <Text
              fontSize={'16px'}
              fontWeight="600"
              lineHeight="130%"
              ml="12px"
            >
              {paymentBeneficiary?.name}{' '}
              <Text
                as="span"
                fontSize={'16px'}
                fontWeight="400"
                lineHeight="130%"
              >
                ({formatCNPJ(paymentBeneficiary?.registrationNumber)})
              </Text>
            </Text>
          </Flex>
        </InputGroup>
        <Text fontSize={'16px'} fontWeight={'600'} mb="8px">
          Data de vencimento do boleto
        </Text>
        <Skeleton isLoaded={!!rechargeInfo}>
          <InputGroup bg="gray.100" marginBottom="32px" borderRadius={'8px'}>
            <InputLeftElement w="54px" h="54px" top="0.7px" cursor="pointer">
              <Flex alignItems="center" color="primary.primary">
                <Icon as={FiFileText} boxSize="20px" />
              </Flex>
            </InputLeftElement>
            <Input
              isReadOnly
              value={
                rechargeInfo?.invoice?.dueDate
                  ? format(rechargeInfo?.invoice?.dueDate, 'dd/MM/yyyy')
                  : ''
              }
              paddingLeft="44px"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              fontWeight={600}
              backgroundColor="gray.100"
            />
          </InputGroup>
        </Skeleton>
        <Flex>
          <Text>
            Como definido na criação da recarga, o valor será creditado aos
            colaboradores no dia{' '}
            <Text as="span" fontWeight={600}>
              {rechargeInfo
                ? format(new Date(rechargeInfo.scheduleDate), 'dd/MM/yyyy')
                : ''}
              .
            </Text>
          </Text>
        </Flex>
      </Box>
    </Box>
  );
};

export default RechargeInfoBoleto;
