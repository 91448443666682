import {
  Box,
  Button,
  Card,
  Flex,
  Icon,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import doctorOne from '../../../../assets/images/doctor-one.png';
import { AlertIcon } from '../../../../assets/customIcons/AlertIcon';
import { FiCalendar, FiSmartphone } from 'react-icons/fi';
import { SvasConfig } from '../../../../types/svas';
import { formatCurrency } from '../../../../utils/formatters/formatCurrency';

const PRODUCT_INFOS = [
  {
    icon: AlertIcon,
    description:
      'No dia do faturamento você receberá um e-mail com a nota fiscal e o boleto para pagamento (o boleto vence em 5 dias a partir da data de faturamento)',
  },
  {
    icon: AlertIcon,
    description:
      'Colaboradores cadastrados no Portal até 1 dia antes da data de faturamento terão o produto habilitado',
  },
  {
    icon: FiCalendar,
    description:
      'Em até 2 dias úteis após o vencimento do boleto os novos colaboradores poderão utilizar o serviço (desde que o boleto tenha sido pago)',
  },
  {
    icon: FiSmartphone,
    description:
      'Lembre-se de informar o número de celular do colaborador no cadastro para facilitar o atendimento dele na DoutorOne',
  },
];

type DoctorOneProductCardProps = {
  totalValue?: SvasConfig['totalValue'];
  defaultBillingDay?: SvasConfig['defaultBillingDay'];
};

const DoctorOneProductCard = ({
  totalValue,
  defaultBillingDay,
}: DoctorOneProductCardProps) => {
  const [openDetails, setOpenDetails] = useState(false);

  return (
    <Card
      width="100%"
      maxWidth="493px"
      padding={6}
      border="2px solid"
      borderColor="gray.300"
      height={openDetails ? 'auto' : 'fit-content'}
    >
      <Text fontSize="20px" fontWeight={500} marginBottom={6}>
        Vida Saudável - Telemedicina
      </Text>

      <Button
        textDecor="underline"
        variant="link"
        fontWeight={500}
        marginBottom={6}
        padding={0}
        onClick={() => setOpenDetails(!openDetails)}
      >
        {openDetails ? 'Ocultar detalhes' : 'Ver detalhes'}
      </Button>

      <Text fontSize="16px" fontWeight={600} marginBottom={4}>
        Faturamento e valor mensal:
      </Text>

      <Flex justifyContent="space-between" gap={1}>
        <Box>
          <Text fontSize="14px">Dia de faturamento</Text>
          <Text fontSize="16px" fontWeight={600}>
            dia {defaultBillingDay} do mês
          </Text>
        </Box>
      </Flex>

      <Text fontSize="14px" marginTop={6}>
        Valor por colaborador
      </Text>
      {totalValue && (
        <Text fontSize="16px" fontWeight={600}>
          {formatCurrency(totalValue)}/mês
        </Text>
      )}

      {openDetails && (
        <Flex flexDirection="column" gap={6} marginTop={4}>
          <VStack
            align="start"
            padding={4}
            spacing={6}
            width="100%"
            backgroundColor="light.orange"
            borderRadius="8px"
            color="black"
          >
            {PRODUCT_INFOS.map((info) => (
              <Flex alignItems="center" gap={6}>
                <Icon as={info.icon} boxSize="24px" color="primary.primary" />
                <Text>{info.description}</Text>
              </Flex>
            ))}
          </VStack>
        </Flex>
      )}

      <Image
        alt="Doctor one logo"
        src={doctorOne}
        width="133px"
        height="25px"
        marginTop={6}
      />
    </Card>
  );
};

export default DoctorOneProductCard;
