import {
  Button,
  Card,
  Checkbox,
  Flex,
  HStack,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  Menu,
  MenuButton,
  IconButton,
} from '@chakra-ui/react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  RowSelectionState,
  SortingState,
  getSortedRowModel,
} from '@tanstack/react-table';
import { useState } from 'react';
import { FiChevronDown, FiMoreVertical } from 'react-icons/fi';
import Pagination from '../../../../components/Pagination/index.tsx';
import { LuPlusCircle, LuUpload } from 'react-icons/lu';
import NewRechargeModal from '../NewRechargeModal/index.tsx';
import { defaultColumns } from '../ColumnHelper/index.tsx';
import SortDirectionIndicator from '../../../../components/SortDirectionIndicator/index.tsx';

import RechargeEmptyState from '../RechargeEmptyState/index.tsx';
import { useNavigate } from 'react-router-dom';
import RechargeActionMenu from '../RechargeActionMenu/index.tsx';
import {
  RechargeListInfo,
  RechargeStatusEnum,
} from '../../../../types/recharge/index.ts';
import CustomTooltip from '../../../../components/CustomTooltip/index.tsx';

type RechargeListProps = {
  rechargeListData: RechargeListInfo[];
};

const RechargeList = ({ rechargeListData }: RechargeListProps) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [columnVisibility, setColumnVisibility] = useState({});
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'createdDate',
      desc: true,
    },
  ]);
  const navigate = useNavigate();
  const table = useReactTable({
    data: rechargeListData,
    columns: defaultColumns,
    state: {
      columnVisibility: columnVisibility,
      rowSelection: rowSelection,
      sorting: sorting,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  const visibleRows = table.getRowModel().rows;

  function checkActionsIsDisabled(recharge: RechargeListInfo) {
    if (
      recharge.status === RechargeStatusEnum.PENDING ||
      recharge.status === RechargeStatusEnum.PROCESSED
    )
      return false;

    return true;
  }

  return (
    <>
      <Flex
        gap={4}
        alignItems="start"
        justifyContent="flex-end"
        marginBottom={10}
      >
        <Button
          leftIcon={<LuUpload size="20px" />}
          variant="outline"
          marginBottom="auto"
          width="fit-content"
          onClick={() => navigate('/recargas/preparar-planilha')}
        >
          Fazer recarga via planilha
        </Button>
        <NewRechargeModal>
          <Button leftIcon={<LuPlusCircle size="20px" />} w="400px">
            Fazer recarga selecionando colaboradores
          </Button>
        </NewRechargeModal>
      </Flex>
      {rechargeListData.length > 0 ? (
        <>
          <Flex
            minHeight="56px"
            justifyContent="flex-end"
            alignItems="center"
            marginTop={3}
            marginBottom={2}
          >
            <HStack spacing={4}>
              <Popover>
                <PopoverTrigger>
                  <Button
                    variant="link"
                    rightIcon={<Icon as={FiChevronDown} />}
                  >
                    Ver colunas
                  </Button>
                </PopoverTrigger>
                <PopoverContent boxShadow="lg" padding={2}>
                  <PopoverArrow />
                  <PopoverCloseButton />

                  <PopoverBody>
                    <VStack align="justify">
                      {table
                        .getAllLeafColumns()
                        .filter((column) => column.id !== 'select')
                        .map((column) => {
                          return (
                            <Checkbox
                              key={column.id}
                              maxWidth="90%"
                              isChecked={column.getIsVisible()}
                              onChange={column.getToggleVisibilityHandler()}
                              padding={1}
                            >
                              {column.columnDef.header as string}
                            </Checkbox>
                          );
                        })}
                    </VStack>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </HStack>
          </Flex>

          <Flex direction="column" gap={4}>
            <Card padding={6} maxWidth="100%" overflowX="auto">
              <Table>
                <Thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <Tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        return (
                          <Th
                            key={header.id}
                            cursor={
                              header.column.getCanSort() ? 'pointer' : 'unset'
                            }
                            onClick={header.column.getToggleSortingHandler()}
                            userSelect="none"
                          >
                            <Flex
                              alignItems="center"
                              gap={2}
                              {...header.column.columnDef.meta}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {header.column.getCanSort() && (
                                <SortDirectionIndicator
                                  direction={header.column.getIsSorted()}
                                />
                              )}
                            </Flex>
                          </Th>
                        );
                      })}
                      <Th textAlign="center">Ações</Th>
                    </Tr>
                  ))}
                </Thead>

                <Tbody>
                  {table.getRowModel().rows.map((row) => (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <Td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Td>
                        );
                      })}
                      <Td>
                        <Flex justifyContent="center">
                          <Menu>
                            <CustomTooltip
                              label="Não há ações disponíveis"
                              isDisabled={!checkActionsIsDisabled(row.original)}
                            >
                              <MenuButton
                                as={IconButton}
                                aria-label="Menu de ações"
                                icon={
                                  <Icon as={FiMoreVertical} boxSize="24px" />
                                }
                                variant="ghost"
                                boxSize="30px"
                                isDisabled={checkActionsIsDisabled(
                                  row.original
                                )}
                              >
                                Ações
                              </MenuButton>
                            </CustomTooltip>
                            <RechargeActionMenu
                              key={row.original.id}
                              recharge={row.original}
                            />
                          </Menu>
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Card>

            <Flex justifyContent="space-between" alignItems="center">
              <Pagination
                currentPage={table.getState().pagination.pageIndex + 1}
                totalPages={table.getPageCount()}
                onChangeRowsPerPage={(option) =>
                  table.setPageSize(option?.value ?? 10)
                }
                handleClickFirstPage={() => table.setPageIndex(0)}
                handleClickNextPage={() => table.nextPage()}
                handleClickPreviousPage={() => table.previousPage()}
                handleClickLastPage={() =>
                  table.setPageIndex(table.getPageCount() - 1)
                }
                elementsOnPage={visibleRows.length}
                totalElements={rechargeListData.length}
              />
            </Flex>
          </Flex>
        </>
      ) : (
        <RechargeEmptyState />
      )}
    </>
  );
};

export default RechargeList;
