import {
  Box,
  Flex,
  Icon,
  Img,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { FiFileText } from 'react-icons/fi';
import { formatCurrencyInCents } from '../../../../utils/formatters/formatCurrency';
import { format } from 'date-fns';
import {
  DocumentTypeEnum,
  RechargePollingResponse,
} from '../../../../types/recharge';
import { DownloadButton } from '../RechargeDocs';
import { formatCNPJ } from '../../../../utils/formatters/formatCNPJ';
import { baseDownloadUrl } from '../../../../constants/general';
import { globalCustomerConfigStore } from '../../../../stores/globalCustomerConfigStore';
import CopyButton from '../../../../components/CopyButton';
import RechargeInfoPageTitle from '../RechargeInfoPageTitle';

type RechargeInfoPixProps = {
  recharge: RechargePollingResponse | undefined;
};

const RechargeInfoPix = ({ recharge }: RechargeInfoPixProps) => {
  const rechargeId = recharge?.rechargeId;
  const rechargeInfo = recharge?.rechargeInfo;

  const pixCopyPaste = rechargeInfo?.pix?.copyPaste;

  const { globalCustomerConfig } = globalCustomerConfigStore();

  const paymentBeneficiary = globalCustomerConfig?.paymentBeneficiary;

  return (
    <Flex
      p={8}
      flexDirection={'column'}
      alignItems="left"
      bg="white"
      w="100%"
      gap={8}
      borderRadius="8px"
      flexWrap={'wrap'}
    >
      <RechargeInfoPageTitle marginBottom={8} isLoaded={!!rechargeInfo} />

      <Text>
        Escaneie a imagem do{' '}
        <Text as="span" fontWeight={600}>
          código QR
        </Text>{' '}
        abaixo com o aplicativo do seu banco para realizar o pagamento da
        recarga:
      </Text>
      <Skeleton isLoaded={!!rechargeInfo} minHeight="228px">
        <Flex flexDirection={'row'} alignItems={'flex-end'} gap="20px">
          <Img
            src={`data:image/png;base64,${rechargeInfo?.pix?.qrCode}`}
            margin="-20px"
            width="100%"
            maxWidth="268px"
          />

          <DownloadButton
            isDisabled={!rechargeInfo}
            maxWidth="fit-content"
            as={Link}
            href={
              rechargeInfo
                ? `${baseDownloadUrl}?rechargeId=${rechargeId}&documentType=${DocumentTypeEnum.PAYMENT}`
                : ''
            }
            height="56px"
          >
            Código QR
          </DownloadButton>
        </Flex>
      </Skeleton>
      <Box>
        <Text fontSize="16px" fontWeight={400} mb="16px">
          Se preferir, pague utilizando o código{' '}
          <Text as="span" fontWeight={600}>
            Pix Copia e Cola
          </Text>
          :
        </Text>

        <Skeleton isLoaded={!!rechargeInfo}>
          <InputGroup marginBottom="32px">
            <InputRightElement
              w="54px"
              h="54px"
              top="0.7px"
              right="29px"
              cursor="pointer"
            >
              <CopyButton copyText={pixCopyPaste || ''} />
            </InputRightElement>
            <Input
              defaultValue={pixCopyPaste}
              pr="105px"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            />
          </InputGroup>
        </Skeleton>

        <Skeleton width="fit-content" isLoaded={!!recharge}>
          <Text
            fontSize="32px"
            fontWeight={600}
            marginBottom="12px"
            width="fit-content"
          >
            {rechargeInfo?.amount && formatCurrencyInCents(rechargeInfo.amount)}
          </Text>
        </Skeleton>

        <Text fontSize={'16px'} fontWeight={'600'} mb="8px">
          Dados do beneficiário
        </Text>
        <InputGroup mb="24px">
          <InputLeftElement w="54px" h="54px" top="0.7px" cursor="pointer">
            <Flex alignItems="center" color="primary.primary">
              <Icon as={FiFileText} boxSize="20px" />
            </Flex>
          </InputLeftElement>
          <Flex
            alignItems="center"
            pl="32px"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            bg="gray.100"
            borderWidth="1px"
            borderColor="gray.300"
            borderRadius="md"
            minHeight="54px"
            flexGrow={1}
          >
            <Text
              fontSize={'16px'}
              fontWeight="600"
              lineHeight="130%"
              ml="12px"
            >
              {paymentBeneficiary?.name}{' '}
              <Text
                as="span"
                fontSize={'16px'}
                fontWeight="400"
                lineHeight="130%"
              >
                ({formatCNPJ(paymentBeneficiary?.registrationNumber)})
              </Text>
            </Text>
          </Flex>
        </InputGroup>
        {rechargeInfo?.pix?.dueDate && (
          <>
            <Text fontSize={'16px'} fontWeight={'600'} mb="8px">
              Data de vencimento do Pix
            </Text>

            <Skeleton isLoaded={!!recharge}>
              <InputGroup marginBottom="16px">
                <InputLeftElement
                  w="54px"
                  h="54px"
                  top="0.7px"
                  cursor="pointer"
                >
                  <Flex alignItems="center" color="primary.primary">
                    <Icon as={FiFileText} boxSize="20px" />
                  </Flex>
                </InputLeftElement>
                <Input
                  isReadOnly
                  defaultValue={format(
                    rechargeInfo?.pix?.dueDate,
                    'dd/MM/yyyy'
                  )}
                  pl="44px"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  background={'gray.100'}
                  fontWeight={600}
                />
              </InputGroup>
            </Skeleton>

            {format(
              new Date(rechargeInfo?.scheduleDate ?? ''),
              'dd/MM/yyyy'
            ) !== format(new Date(), 'dd/MM/yyyy') ? (
              <Flex>
                <Text fontSize={'16px'} fontWeight="400" lineHeight="130%">
                  Como definido na criação da recarga, o valor será creditado
                  aos colaboradores no dia{' '}
                  <Text
                    as="span"
                    fontSize={'16px'}
                    fontWeight="600"
                    lineHeight="130%"
                  >
                    {format(
                      new Date(rechargeInfo?.scheduleDate ?? ''),
                      'dd/MM/yyyy'
                    )}
                  </Text>
                </Text>
              </Flex>
            ) : (
              <Flex>
                <Text fontSize={'16px'} fontWeight="400" lineHeight="130%">
                  Assim que o pagamento for confirmado, o valor será creditado
                  aos colaboradores
                </Text>
              </Flex>
            )}
          </>
        )}
      </Box>
    </Flex>
  );
};

export default RechargeInfoPix;
