import { useEffect } from 'react';
import { useSwitchFirstAccessCompany } from '../../api/company';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import { Flex } from '@chakra-ui/react';
import { isAxiosError } from 'axios';
import { wait } from '../../utils/wait';

const SwitchCompanyPage = () => {
  const { confirmationToken } = useParams();
  const { mutate } = useSwitchFirstAccessCompany();
  const navigate = useNavigate();

  useEffect(() => {
    if (!confirmationToken) return;
    mutate(
      {
        token: confirmationToken,
      },
      {
        onSuccess: () => {
          navigate('/');
        },
        onError: async (error) => {
          if (isAxiosError(error)) {
            if ([401, 404, 498].includes(error.response?.status || 0)) {
              navigate('/sair');
              await wait(100);
              navigate(`/definicao-de-senha/${confirmationToken}`);
            }
          }
        },
      }
    );
  }, [mutate, confirmationToken, navigate]);

  return (
    <Flex alignItems="center" justifyContent="center" minHeight="100vh">
      <Spinner size="xl" />
    </Flex>
  );
};

export default SwitchCompanyPage;
