import { Box, Heading } from '@chakra-ui/react';
import { useUserCompaniesStore } from '../../stores/useUserCompaniesStore';
import { Navigate } from 'react-router-dom';
import ProductList from './components/ProductList';

const ProductsPage = () => {
  const { selectedCompany } = useUserCompaniesStore();

  const companyHasProductsPage =
    selectedCompany?.origin === 'INTER' &&
    selectedCompany?.svas?.products?.includes('DoutorOne');

  if (!companyHasProductsPage) {
    return <Navigate to="/" />;
  }

  return (
    <Box>
      <Heading fontSize="20px" fontWeight={500}>
        Produtos contratados
      </Heading>

      <Box marginTop={5}>
        <ProductList />
      </Box>
    </Box>
  );
};

export default ProductsPage;
