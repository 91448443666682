import {
  Card,
  Flex,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Text,
  FormLabel,
  FormControl,
  Drawer,
  DrawerContent,
  DrawerBody,
  Button,
  HStack,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  VStack,
  Checkbox,
  Link,
} from '@chakra-ui/react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  createColumnHelper,
} from '@tanstack/react-table';
import { FiChevronDown, FiSearch, FiTrash2 } from 'react-icons/fi';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Pagination from '../../../../components/Pagination';
import SortDirectionIndicator from '../../../../components/SortDirectionIndicator';
import { useDebounce } from '../../../../hooks/useDebounce';
import { maskCpfForPrivacy } from '../../../../utils/formatters/maskCpfForPrivacy';
import CustomTooltip from '../../../../components/CustomTooltip';
import InputWithConfirmation from '../../../../components/InputWithConfirmation';
import { useSkipper } from '../../rechargeTableHelpers';
import EditableCell from '../EditableCell';
import AlertDialog from '../../../../components/AlertDialog';
import { useToast } from '@chakra-ui/react';
import ActionDrawerInfoCard from '../ActionDrawerInfoCard';
import { useNavigate } from 'react-router-dom';
import {
  checkIfSomeEmployeeReceiveDailyBenefits,
  sumBenefitValues,
  sumBenefitValuesPerEmployee,
} from '../../utils';
import { formatCurrencyInCents } from '../../../../utils/formatters/formatCurrency';
import Select from '../../../../components/Select';
import { WORKING_DAYS_OPTIONS } from '../../../../constants/general';
import Spinner from '../../../../components/Spinner';
import ContactModalButton from '../../../../components/ContactModalButton';
import { Employee, EmployeeBenefitCategory } from '../../../../types/employee';
import HelpMessage from '../../../../components/HelpMessage';
import CurrencyInput from '../../../../components/CurrencyInput';
import BenefitDisplay from '../../../../components/BenefitDisplay';
import { BenefitCategories, BenefitCategory } from '../../../../types/benefit';
import { DocumentSignedIcon } from '../../../../assets/customIcons/DocumentSignedIcon';
import { fuzzyFilter } from '../../../EmployeesPage/components/EmployeesList/employeesTableHelpers';
import { useIsSwitchingCompany } from '../../../../hooks/useIsSwitchingCompany';
import { ReduceIcon } from '../../../../assets/customIcons/ReduceIcon';
import { ExpansionIcon } from '../../../../assets/customIcons/ExpansionIcon';
import RechargeStepper from '../RechargeStepper';
import { getUniqueCategories } from '../../utils/getUniqueCategories';
import { syncEmployeeCategoriesWithCompanyBenefits } from '../../utils/syncEmployeeCategoriesWithCompanyBenefits';

export type RechargeTableProps = {
  employees: Employee[];
  rechargeCompetencyInDays: number | undefined;
  isFullScreen: boolean;
  setIsFullScreen: (value: boolean) => void;
  isSpreadsheetImport: boolean | undefined;
  benefitCategories: EmployeeBenefitCategory[];
};

const columnHelper = createColumnHelper<Employee>();

const RechargeTable = ({
  employees,
  rechargeCompetencyInDays,
  isFullScreen,
  setIsFullScreen,
  isSpreadsheetImport,
  benefitCategories,
}: RechargeTableProps) => {
  const [rechargeEmployeesData, setRechargeEmployeesData] = useState(() =>
    syncEmployeeCategoriesWithCompanyBenefits(employees, benefitCategories)
  );

  const [numOfDays, setNumOfDays] = useState(() => rechargeCompetencyInDays);
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'name',
      desc: false,
    },
  ]);
  const [globalFilter, setGlobalFilter] = useState('');
  const debouncedGlobalFilter = useDebounce<string>(globalFilter, 700);

  const isSwitchingCompany = useIsSwitchingCompany();

  const modalRemoveColaborator = useDisclosure();
  const toast = useToast();
  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();
  const [colaboratorToRemove, setColaboratorToRemove] = useState<{
    id?: string;
    name?: string;
  }>({});
  const handleRemoveColaborator = () => {
    setRechargeEmployeesData((prev) =>
      prev.filter((employee) => employee.id !== colaboratorToRemove.id)
    );
    modalRemoveColaborator.onClose();
    toast({
      title: 'Colaborador removido da recarga com sucesso',
      status: 'success',
    });
    setColaboratorToRemove({});
  };
  const navigate = useNavigate();

  const availableBenefitCategories = useMemo(() => {
    return getUniqueCategories(employees);
  }, [employees]);

  const rechargeEmployees = rechargeEmployeesData;

  const grandTotal = useMemo(
    () => sumBenefitValues(rechargeEmployees),
    [rechargeEmployees]
  );

  const rechargeTableColumns = useMemo(() => {
    return [
      columnHelper.display({
        id: 'removeEmployee',
        meta: {
          background: 'gray.50',
        },
        cell: ({ row }) => (
          <CustomTooltip label="Remover colaborador da recarga">
            <IconButton
              aria-label="Remover colaborador desta recarga"
              icon={<Icon as={FiTrash2} boxSize="20px" />}
              variant="ghost"
              boxSize="24px"
              borderRadius="md"
              display="flex"
              alignItems="center"
              onClick={() => {
                setColaboratorToRemove({
                  id: row.original.id,
                  name: row.original.name,
                });
                modalRemoveColaborator.onOpen();
              }}
            />
          </CustomTooltip>
        ),
      }),
      columnHelper.accessor('name', {
        cell: (info) => <Text>{info.getValue()}</Text>,
        header: 'Nome',
        enableColumnFilter: true,
        enableHiding: false,
        sortingFn: (a, b) => {
          return a.original.name.localeCompare(b.original.name);
        },
        meta: {
          minWidth: '250px',
          position: 'sticky',
          left: 0,
          zIndex: 1,
          bg: 'white',
          boxShadow: '-17px 10px 3px -18px rgba(0,0,0,0.50) inset',
          background: 'gray.50',
        },
      }),
      columnHelper.accessor('cpf', {
        cell: (info) => {
          const cpf = info.getValue();
          return <Text> {maskCpfForPrivacy(cpf)}</Text>;
        },
        header: 'CPF',
        enableColumnFilter: true,
        meta: {
          minWidth: '180px',
        },
      }),
      columnHelper.accessor('packageName', {
        cell: (info) => {
          return <Text>{info.getValue()}</Text>;
        },
        header: 'Pacote',
        enableColumnFilter: true,
      }),
      ...(availableBenefitCategories.length > 0
        ? availableBenefitCategories.map((category) => {
            return columnHelper.accessor(
              (row) => {
                const categoryIndex = row.categories.findIndex(
                  (cat) => cat.id === category.id
                );
                return row.categories[categoryIndex];
              },
              {
                cell: (info) => {
                  return (
                    <Flex
                      alignItems="center"
                      gap={2}
                      title={info.row.original.name}
                    >
                      <EditableCell {...info} />
                    </Flex>
                  );
                },
                id: category.id,
                enableColumnFilter: false,
                enableSorting: false,
                meta: { minWidth: '280px', title: category.name },
                footer: category.name,
                header: () => {
                  return (
                    <BenefitDisplay
                      benefit={
                        {
                          category: category as unknown as BenefitCategory,
                        } as BenefitCategories
                      }
                    />
                  );
                },
              }
            );
          })
        : []),
    ];
    // removed modalRemoveColaborator because it was causing recalculation of the columns on every render, breaking the navigation via tab
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableBenefitCategories]);

  const table = useReactTable({
    data: rechargeEmployees,
    columns: rechargeTableColumns,
    state: {
      sorting: sorting,
      globalFilter: debouncedGlobalFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: fuzzyFilter,
    autoResetPageIndex: autoResetPageIndex,
    meta: {
      updateData: (rowIndex, _columnId, value) => {
        const editedCategory: EmployeeBenefitCategory = {
          ...(value as EmployeeBenefitCategory),
          edited: true,
        };

        // Skip page index reset until after next rerender
        skipAutoResetPageIndex();

        setRechargeEmployeesData((prevEmployees) => {
          const newEmployees = [...prevEmployees];
          const categoryIndex = newEmployees[rowIndex].categories.findIndex(
            (category) => category.id === editedCategory.id
          );
          newEmployees[rowIndex].categories[categoryIndex] = {
            ...editedCategory,
            edited: editedCategory.edited ?? false,
          };
          return newEmployees;
        });
      },
    },
  });

  const numOfEmployees = table.getCoreRowModel().rows.length;
  const visibleRows = table.getRowModel().rows ?? [];
  const isValidRecharge =
    numOfEmployees > 0 &&
    grandTotal > 0 &&
    availableBenefitCategories.length > 0;

  const someEmployeesReceiveDailyBenefits = useMemo(
    () => checkIfSomeEmployeeReceiveDailyBenefits(rechargeEmployees),
    [rechargeEmployees]
  );

  const isFiltered = table.getFilteredRowModel().rows.length !== numOfEmployees;

  const handleMassValueEdit = useCallback(
    (benefitCategory: EmployeeBenefitCategory, value: string) => {
      if (value === '') {
        return;
      }
      setRechargeEmployeesData((prevEmployees) =>
        prevEmployees.map((employee) => {
          const categoryIndex = employee.categories.findIndex(
            (cat) => cat.id === benefitCategory.id
          );

          if (categoryIndex === -1) {
            return employee;
          }

          employee.categories[categoryIndex].value = parseInt(value);
          employee.categories[categoryIndex].edited = true;

          return {
            ...employee,
          };
        })
      );
    },
    []
  );

  useEffect(() => {
    if (!numOfDays || !someEmployeesReceiveDailyBenefits) {
      return;
    }

    setRechargeEmployeesData((prevEmployees) =>
      prevEmployees.map((prevEmployee) => {
        const employee = employees.find((e) => e.id === prevEmployee.id);

        if (!employee) {
          return prevEmployee;
        }

        const newCategories = prevEmployee.categories.map((category) => {
          const employeeCategory = employee.categories.find(
            (cat) => cat.id === category.id
          );

          if (
            category.period === 'DAILY' &&
            !category.edited &&
            employeeCategory
          ) {
            return {
              ...category,
              value: employeeCategory.value * numOfDays,
            };
          }

          return category;
        });

        return {
          ...prevEmployee,
          categories: newCategories,
        };
      })
    );
  }, [employees, numOfDays, someEmployeesReceiveDailyBenefits]);

  if (isSwitchingCompany) {
    return <Spinner />;
  }

  if (availableBenefitCategories.length === 0) {
    return (
      <Alert
        status="error"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="200px"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          Ops! Não foi possível encontrar nenhuma categoria de benefícios para
          essa empresa.
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          Entre em contato com o nosso suporte. <ContactModalButton />
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <Box marginTop={isFullScreen ? 4 : 0}>
      {!isFullScreen && (
        <Box marginBottom={6}>
          <RechargeStepper isSpreadsheetImport={isSpreadsheetImport} />
        </Box>
      )}
      <Flex
        minHeight="56px"
        justifyContent="space-between"
        alignItems="flex-end"
        marginBottom={4}
      >
        <InputGroup maxWidth="360px">
          <Input
            type="search"
            placeholder="Pesquisar por nome ou CPF"
            value={globalFilter}
            onChange={(e) => setGlobalFilter(String(e.target.value))}
            backgroundColor="gray.50"
            paddingRight={12}
          />
          <InputRightElement>
            <Icon as={FiSearch} boxSize="16px" color="primary.primary" />
          </InputRightElement>
        </InputGroup>

        <Flex alignItems="flex-end" gap={4}>
          <Popover>
            <PopoverTrigger>
              <Button
                variant="link"
                rightIcon={<Icon as={FiChevronDown} />}
                padding={0}
              >
                Ver colunas
              </Button>
            </PopoverTrigger>
            <Box zIndex={11}>
              <PopoverContent
                boxShadow="lg"
                padding={2}
                maxHeight={{
                  base: '200px',
                  xl: '320px',
                  '2xl': '474px',
                }}
                overflow={'auto'}
              >
                <PopoverArrow />
                <PopoverCloseButton />

                <PopoverBody>
                  <VStack align="justify">
                    {table
                      .getAllLeafColumns()
                      .filter((column) => column.id !== 'removeEmployee')
                      .map((column) => {
                        if (column.getCanHide()) {
                          const label =
                            column.columnDef.footer || column.columnDef.header;
                          if (label === 'CPF' || label === 'Pacote') {
                            return null;
                          }
                          return (
                            <Checkbox
                              key={column.id}
                              maxWidth="90%"
                              isChecked={column.getIsVisible()}
                              onChange={column.getToggleVisibilityHandler()}
                              padding={1}
                            >
                              {label as string}
                            </Checkbox>
                          );
                        }
                      })}
                  </VStack>
                </PopoverBody>
              </PopoverContent>
            </Box>
          </Popover>

          {someEmployeesReceiveDailyBenefits && (
            <FormControl maxWidth="160px">
              <FormLabel fontSize="sm" htmlFor="numOfDays">
                Quantidade de dias
              </FormLabel>
              <Select
                inputId="numOfDays"
                width="160px"
                options={WORKING_DAYS_OPTIONS}
                value={WORKING_DAYS_OPTIONS.find(
                  (option) => option.value === numOfDays
                )}
                onChange={(option) => setNumOfDays(option?.value ?? 1)}
              />
            </FormControl>
          )}
        </Flex>
      </Flex>
      <Flex direction="column" gap={4} marginBottom="140px">
        <Card
          padding={0}
          maxWidth="100%"
          overflowX="auto"
          maxHeight="60vh"
          overflowY="auto"
        >
          <Table>
            <Thead
              position="sticky"
              top={0}
              zIndex="docked"
              background="gray.50"
              boxShadow="0px 5px 5px -8px rgba(0,0,0,0.75)"
            >
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id} paddingTop={5}>
                  {headerGroup.headers.map((header) => (
                    <Th
                      height="90px"
                      key={header.id}
                      cursor={header.column.getCanSort() ? 'pointer' : 'unset'}
                      onClick={header.column.getToggleSortingHandler()}
                      userSelect="none"
                      {...header.column.columnDef.meta}
                    >
                      <Flex alignItems="flex-end" gap={2} height="100%">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getCanSort() && (
                          <SortDirectionIndicator
                            direction={header.column.getIsSorted()}
                          />
                        )}
                      </Flex>
                    </Th>
                  ))}
                  <Th>
                    <Flex
                      alignItems="flex-end"
                      gap={1}
                      height="41.6px"
                      fontWeight={600}
                      color="primary.primary"
                    >
                      <DocumentSignedIcon />
                      <Text minWidth="230px">Valor total por colaborador</Text>
                    </Flex>
                  </Th>
                </Tr>
              ))}
            </Thead>

            <Tbody>
              {numOfEmployees > 1 && !isFiltered && (
                <Tr backgroundColor="gray.50">
                  <Td colSpan={1} background="inherit">
                    <Flex justifyContent="center">
                      <HelpMessage
                        label="Aqui você pode editar os valores para todos os colaboradores de uma vez só."
                        iconProps={{
                          boxSize: 6,
                        }}
                      />
                    </Flex>
                  </Td>
                  <Td
                    colSpan={1}
                    position="sticky"
                    left={0}
                    background="inherit"
                    minWidth="200px"
                    boxShadow="-17px 10px 3px -18px rgba(0,0,0,0.50) inset"
                    fontWeight={600}
                    color="primary.primary"
                  >
                    Alterar valor para todos
                  </Td>

                  <Td />
                  <Td />
                  {availableBenefitCategories.map((category) => {
                    const benefitCategory = availableBenefitCategories.find(
                      (cat) => cat.id === category.id
                    );
                    if (!benefitCategory) {
                      return null;
                    }
                    const isColumnVisible = table
                      .getVisibleFlatColumns()
                      .map((col) => col.id)
                      .includes(benefitCategory.id);

                    if (!isColumnVisible) {
                      return null;
                    }
                    return (
                      <Td key={benefitCategory.id} id={benefitCategory.id}>
                        <InputWithConfirmation
                          title="Todos"
                          type="currency"
                          onSubmit={(value) =>
                            handleMassValueEdit(benefitCategory, value)
                          }
                        />
                      </Td>
                    );
                  })}
                  <Td />
                </Tr>
              )}
              {table.getRowModel().rows.map((row) => (
                <Tr
                  key={row.id}
                  _hover={{
                    background: 'gray.50',
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id} {...cell.column.columnDef.meta}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}
                  <Td id={'total-value-per-employee-' + row.original.id}>
                    <CurrencyInput
                      isReadOnly
                      backgroundColor="gray.100"
                      value={sumBenefitValuesPerEmployee(row.original)}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Card>
        {modalRemoveColaborator.isOpen && (
          <AlertDialog
            isOpen={modalRemoveColaborator.isOpen}
            onClose={modalRemoveColaborator.onClose}
            cancelButtonAction={modalRemoveColaborator.onClose}
            title={`Remover colaborador da recarga?`}
            confirmButtonLabel="Remover"
            confirmButtonAction={handleRemoveColaborator}
          >
            {`Deseja realmente remover ${colaboratorToRemove.name} desta recarga?`}
          </AlertDialog>
        )}

        <Flex justifyContent="space-between" alignItems="center">
          <Pagination
            currentPage={table.getState().pagination.pageIndex + 1}
            totalPages={table.getPageCount()}
            onChangeRowsPerPage={(option) =>
              table.setPageSize(option?.value ?? 10)
            }
            handleClickFirstPage={() => table.setPageIndex(0)}
            handleClickNextPage={() => table.nextPage()}
            handleClickPreviousPage={() => table.previousPage()}
            handleClickLastPage={() =>
              table.setPageIndex(table.getPageCount() - 1)
            }
          />

          <Flex
            alignItems="center"
            cursor="pointer"
            onClick={() => setIsFullScreen(!isFullScreen)}
          >
            <Button
              variant="link"
              fontWeight={600}
              marginLeft={3}
              leftIcon={
                <Icon
                  as={isFullScreen ? ReduceIcon : ExpansionIcon}
                  boxSize={'20px'}
                />
              }
            >
              {isFullScreen ? 'Reduzir' : 'Tela cheia'}
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <Drawer
        placement="bottom"
        isOpen={isValidRecharge}
        autoFocus={false}
        blockScrollOnMount={false}
        returnFocusOnClose={false}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        trapFocus={false}
        lockFocusAcrossFrames={false}
        variant="clickThrough"
        onClose={() => {}}
      >
        <DrawerContent zIndex={1} paddingX={20} height="144px">
          <DrawerBody>
            <Flex
              direction="row-reverse"
              alignItems="center"
              gap={4}
              height="100%"
              justifyContent="space-between"
            >
              <Flex gap={4}>
                <Button
                  variant="outline"
                  onClick={() =>
                    navigate('/recargas/nova-recarga/resumo', {
                      state: {
                        employees: rechargeEmployees,
                        grandTotal,
                        benefitCategories: availableBenefitCategories,
                        numOfDays,
                        isSpreadsheetImport,
                      },
                    })
                  }
                >
                  Ver resumo antes de continuar
                </Button>
                <Button
                  onClick={() =>
                    navigate('/recargas/nova-recarga/finalizar-pedido', {
                      state: {
                        employees: rechargeEmployees,
                        grandTotal,
                        benefitCategories: availableBenefitCategories,
                        numOfDays,
                        isSpreadsheetImport,
                      },
                    })
                  }
                >
                  Continuar
                </Button>
              </Flex>
              <HStack spacing={4}>
                <ActionDrawerInfoCard
                  label="Colaboradores na recarga"
                  value={numOfEmployees}
                />
                <ActionDrawerInfoCard
                  label="Valor total"
                  value={formatCurrencyInCents(grandTotal)}
                />
              </HStack>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default RechargeTable;
