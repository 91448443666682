import { useQuery } from '@tanstack/react-query';
import { useFetchContext } from '../../hooks/useFetchContext';
import { Invoice } from '../../types/recharge';

export const useGetInvoice = (
  enabled: boolean,
  rechargeId: string | undefined
) => {
  const { api } = useFetchContext();

  return useQuery({
    queryKey: ['invoice', { rechargeId }],
    queryFn: () => api.get<Invoice>(`/api/recharges/${rechargeId}/invoice`),
    select: (response) => response.data,
    refetchInterval: 5000,
    enabled: enabled && !!rechargeId,
  });
};
