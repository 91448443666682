import {
  Box,
  Flex,
  IconButton,
  Image,
  Link as ChakraLink,
  Stack,
  useDisclosure,
  HStack,
} from '@chakra-ui/react';
import { TbCircleChevronLeft } from 'react-icons/tb';
import NavItem from './NavItem';
import {
  ConfigIcon,
  UserHeartIcon,
  RechargesIcon,
} from '../../assets/customIcons';
import { Link, Outlet, useLocation } from 'react-router-dom';
import Header from './Header';
import { useEffect, useLayoutEffect } from 'react';
import { UsersIcon } from '../../assets/customIcons/UsersIcon';
import logoO from '../../assets/o.svg';
import logoRaio from '../../assets/raio.svg';
import { isActivePath } from '../../utils/validations/isActivePath';
import { PhoneIcon } from '../../assets/customIcons/PhoneIcon';
import ContactModalButton from '../ContactModalButton';
import { BoxIcon } from '../../assets/customIcons/BoxIcon';
import { useIsSwitchingCompany } from '../../hooks/useIsSwitchingCompany';
import Spinner from '../Spinner';
import { stripUuid } from '../../utils/stripUuidFromPath';
import { useGetCustomerConfigs } from '../../api/configs';
import { CustomerConfig } from '../../types/config';
import { globalCustomerConfigStore } from '../../stores/globalCustomerConfigStore';
import { BiWallet } from 'react-icons/bi';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { CommentInfoIcon } from '../../assets/customIcons/CommentInfoIcon';
import SupportNavItem from './SupportNavItem';
import { HandHearthIcon } from '../../assets/customIcons/HandHearthIcon';
import { useUserCompaniesStore } from '../../stores/useUserCompaniesStore';

const SIDEBAR_OPTIONS = [
  {
    id: 1,
    href: '/pacotes-de-beneficios',
    label: 'Pacotes de Benefícios',
    icon: <BoxIcon />,
  },
  {
    id: 2,
    href: '/colaboradores',
    label: 'Colaboradores',
    icon: <UserHeartIcon />,
  },
  {
    id: 3,
    href: '/recargas',
    label: 'Recargas',
    icon: <RechargesIcon />,
  },
  {
    id: 4,
    href: '/creditos-raio',
    label: 'Créditos Raiô',
    icon: <BiWallet size="24px" />,
  },
  {
    id: 7,
    href: '/produtos',
    label: 'Produtos',
    icon: <HandHearthIcon boxSize="24px" />,
  },
  {
    id: 6,
    href: '/configuracoes',
    label: 'Configurações',
    icon: <ConfigIcon />,
  },
  {
    id: 7,
    href: '/usuarios',
    label: 'Usuários do Portal',
    icon: <UsersIcon />,
  },
];

const LOCATIONS_WITHOUT_HEADER = [
  '/colaboradores/importar/preparar-planilha',
  '/colaboradores/importar/comunicacao',
  '/colaboradores/importar/configurar-importacao',
  '/nova-via',
  '/colaboradores/cadastrar-colaborador',
  '/recargas/nova-recarga',
  '/recargas/nova-recarga/resumo',
  '/recargas/nova-recarga/finalizar-pedido',
  '/recargas/preparar-planilha',
  '/recargas/editar-recarga',
  '/colaboradores/estornar-saldo',
  '/creditos-raio/pedido',
];

function shouldShowHeader(location: string): boolean {
  return !LOCATIONS_WITHOUT_HEADER.includes(stripUuid(location));
}
const AppShell = () => {
  const { isOpen, onToggle, onClose } = useDisclosure({
    defaultIsOpen: true,
  });

  const isSmallerThan992 = useMediaQuery('(max-width: 992px)');

  const location = useLocation();

  const isSwitchingCompany = useIsSwitchingCompany();

  useLayoutEffect(() => {
    if (isOpen && isSmallerThan992) {
      onClose();
    }
  }, [isOpen, isSmallerThan992, onClose]);

  const { data: customerConfigArray = [], isLoading: isLoadingCustomerConfig } =
    useGetCustomerConfigs();
  const customerConfig = customerConfigArray[0] as CustomerConfig | undefined;

  const { setCustomerConfig } = globalCustomerConfigStore();

  const logo = isOpen ? logoRaio : logoO;

  const { selectedCompany } = useUserCompaniesStore();

  const companyHasProductsPage =
    selectedCompany?.origin === 'INTER' &&
    selectedCompany?.svas?.products?.includes('DoutorOne');

  useEffect(() => {
    if (customerConfig) {
      setCustomerConfig(customerConfig);
    }
  }, [customerConfig, setCustomerConfig]);

  return (
    <Box>
      {shouldShowHeader(location.pathname) && <Header isOpen={isOpen} />}
      <Flex id="app-container" minHeight="calc(100vh - 90px)">
        <Box
          width={isOpen ? 64 : 20}
          backgroundColor="gray.50"
          color="gray.black"
          fontSize="sm"
          transition="width 0.3s"
          position="fixed"
          zIndex={500}
          height="100vh"
          top={0}
        >
          <Box
            w="full"
            display="flex"
            justifyContent="center"
            mt="20px"
            mb="32px"
          >
            <ChakraLink as={Link} to="/">
              <Image src={logo} height="50px" />
            </ChakraLink>
          </Box>

          <Flex direction="column" position="relative">
            <IconButton
              onClick={onToggle}
              title="Minimizar menu"
              aria-label="Minimizar menu"
              color="primary.primary"
              backgroundColor="gray.50"
              position="absolute"
              variant="unstyled"
              width="25px"
              minWidth={0}
              padding={0}
              height="25px"
              transform={isOpen ? 'rotate(0deg)' : 'rotate(-180deg)'}
              transition={'transform 0.3s'}
              icon={<TbCircleChevronLeft fontSize="25px" />}
              top="-25px"
              right="-14px"
              boxShadow="sm"
              display={isSmallerThan992 ? 'none' : 'block'}
            />
            <Flex
              direction="column"
              justifyContent="space-between"
              flex={1}
              padding={4}
              overflow="auto"
              paddingTop={4}
              overflowX="hidden"
            >
              <Stack spacing={4}>
                {SIDEBAR_OPTIONS.map(({ href, icon, label }) => {
                  if (href === '/produtos' && !companyHasProductsPage) {
                    return null;
                  }

                  return (
                    <NavItem
                      key={href}
                      active={isActivePath(location.pathname, href)}
                      icon={icon}
                      label={label}
                      showLabel={isOpen}
                      href={href}
                    />
                  );
                })}
                <ContactModalButton
                  height="fit-content"
                  width="full"
                  borderRadius="8px"
                  _focusVisible={{
                    boxShadow: 'none',
                    outline: '2px solid black',
                  }}
                >
                  <SupportNavItem
                    icon={<PhoneIcon color="primary.primary" />}
                    label="Falar com a Raiô"
                    isOpen={isOpen}
                  />
                </ContactModalButton>

                <Box
                  onClick={() =>
                    window.open('https://central.raiobeneficios.com/', '_blank')
                  }
                >
                  <SupportNavItem
                    icon={<CommentInfoIcon color="primary.primary" />}
                    label="Central de ajuda"
                    isOpen={isOpen}
                  />
                </Box>
              </Stack>
            </Flex>
          </Flex>
        </Box>
        <Box
          flex={1}
          padding={6}
          minHeight="100%"
          paddingLeft={isOpen ? 72 : 28}
          transition={'padding-left 0.3s'}
          maxWidth="calc(100vw - 8px)"
        >
          {isSwitchingCompany || isLoadingCustomerConfig ? (
            <Spinner />
          ) : (
            <Outlet />
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default AppShell;
