import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UseDisclosureReturn,
} from '@chakra-ui/react';

import { FiClock } from 'react-icons/fi';
import {} from '../../../../components/Typography';

import { useNavigate } from 'react-router-dom';
import AlertCard from '../../../../components/AlertCard';

type ExceptionPollingModalProps = {
  disclosureProps: UseDisclosureReturn;
};

const ExceptionPollingModal = ({
  disclosureProps,
}: ExceptionPollingModalProps) => {
  const { isOpen, onClose } = disclosureProps;
  const navigate = useNavigate();
  return (
    <Modal size="xl" isOpen={isOpen} onClose={() => {}} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex flexDirection="row" alignItems="center" gap="2">
            <Icon as={FiClock} color="status.loading" />
            Recarga em processamento
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Text marginBottom="4">
            Parece que esta recarga{' '}
            <Text as="span" fontWeight="600">
              está levando mais tempo que o previsto
            </Text>{' '}
            para ser criada.
          </Text>
          <Text marginBottom="4">
            Fique tranquilo!{' '}
            <Text as="span" fontWeight="600">
              Em breve os documentos estarão disponíveis
            </Text>{' '}
            na{' '}
            <Text as="span" fontWeight="600">
              lista de recargas
            </Text>{' '}
            e você poderá realizar o pagamento.
          </Text>

          <AlertCard iconcolor="#DE8706" marginTop="10" marginBottom="5">
            <Text>
              Você{' '}
              <Text as="span" fontWeight="600">
                não precisa criar outra
              </Text>{' '}
              recarga, acesse a{' '}
              <Text as="span" fontWeight="600">
                lista de recargas
              </Text>{' '}
              e acompanhe a atualização do status ”Em processamento” para
              ”Criada”
            </Text>
          </AlertCard>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button width="100%" onClick={() => navigate('/recargas')}>
            Ir para a lista de recargas
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ExceptionPollingModal;
