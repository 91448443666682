import { forwardRef } from 'react';

import { Input, InputProps } from '@chakra-ui/react';

import { addressMaxLength } from '../../constants/general';

const AddressNumberInput = forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    return (
      <Input
        ref={ref}
        type="text"
        {...props}
        maxLength={addressMaxLength.number}
        onInput={(event) =>
          (event.currentTarget.value = event.currentTarget.value.replace(
            /[^0-9]/g,
            ''
          ))
        }
      />
    );
  }
);

export default AddressNumberInput;
