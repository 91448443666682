import {
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useClipboard,
  useDisclosure,
} from '@chakra-ui/react';
import {
  FiCheckCircle,
  FiCopy,
  FiCreditCard,
  FiDollarSign,
  FiEdit,
  FiMoreVertical,
  FiPower,
  FiSlash,
} from 'react-icons/fi';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import RechargeCompetencyModal from './RechargeCompetencyModal';
import { checkIfSomeEmployeeReceiveDailyBenefits } from '../../../NewRechargePage/utils';
import { useBlockCard } from '../../../../api/card';
import {
  useDisableEmployees,
  useGetEmployeeInfo,
  useSendCommsToEmployees,
} from '../../../../api/employees';
import { MailPlusIcon } from '../../../../assets/customIcons/MailPlusIcon';
import AlertDialog from '../../../../components/AlertDialog';
import { Employee, EmployeeStatusEnum } from '../../../../types/employee';
import { TruckIcon } from '../../../../assets/customIcons/TruckIcon';
import TrackingCardModal from '../TrackingCardModal';
import NewCardModal from './NewCardModal';
import { WalletIcon } from '../../../../assets/customIcons';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import DisableEmployeeCardDisplay from './DisableEmployeeCardDisplay';
import { disableSingleEmployeeHelperTexts } from './DisableEmployeeHelperTexts';
import { Semibold } from '../../../../components/Typography';

type EmployeeActionMenuProps = {
  employee: Employee;
};

const { ACTIVE, WAITING_ONBOARDING, PENDING, INACTIVE } = EmployeeStatusEnum;

const EmployeeActionMenu = memo(({ employee }: EmployeeActionMenuProps) => {
  const employeeName = employee.name;
  const employeeActivationCode = employee.activationCode || '';
  const { hasCopied, onCopy } = useClipboard(employeeActivationCode);
  const disableEmployeeConfirmationAlert = useDisclosure();
  const sendCommsConfirmationAlert = useDisclosure();
  const blockCardConfirmationAlert = useDisclosure();
  const rechargeCompetencyConfirmationAlert = useDisclosure();
  const newCardModal = useDisclosure();
  const navigate = useNavigate();
  const isInactiveEmployee: boolean = employee.status === INACTIVE;

  const employeeReceiveDailyBenefits = checkIfSomeEmployeeReceiveDailyBenefits([
    employee,
  ]);

  const showTrackingCard =
    employee.cardType === 'NOMINAL' &&
    (employee.cardStatus === 'ACTIVE' ||
      employee.cardStatus === 'PENDING' ||
      employee.cardStatus === 'BLOCKED');

  const blockCard = useBlockCard();
  const sendCommsToEmployees = useSendCommsToEmployees();
  const disableEmployees = useDisableEmployees();

  const isActiveEmployee =
    employee.status === EmployeeStatusEnum.ACTIVE ||
    employee.status === EmployeeStatusEnum.WAITING_ONBOARDING;

  const { isAdmin } = useAuthContext();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const shouldEnableRefund =
    isAdmin && [ACTIVE, WAITING_ONBOARDING, PENDING].includes(employee.status);

  const { data, isLoading } = useGetEmployeeInfo(
    employee.cpf,
    isOpen && shouldEnableRefund
  );

  const employeeInfo = data?.data[0];

  return (
    <Menu isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <MenuButton
        as={IconButton}
        aria-label="Menu de ações"
        icon={<Icon as={FiMoreVertical} boxSize="24px" />}
        variant="ghost"
        boxSize="30px"
      >
        Ações
      </MenuButton>
      <MenuList>
        {!isActiveEmployee && employeeActivationCode && !isInactiveEmployee && (
          <MenuItem
            title="Clique para copiar"
            onClick={onCopy}
            closeOnSelect={false}
            minWidth="295px"
            minHeight="36px"
          >
            {hasCopied ? (
              <Flex alignItems="center" gap={2} color="feedback.success">
                <Icon as={FiCheckCircle} boxSize="20px" />
                <Text fontWeight={600}>Código copiado!</Text>
              </Flex>
            ) : (
              <>
                <Text>
                  Código de ativação:
                  <Text as="span" fontWeight={600} marginLeft={2}>
                    {employeeActivationCode}
                  </Text>
                </Text>
                <IconButton
                  as="div"
                  onClick={onCopy}
                  boxSize={6}
                  variant="ghost"
                  aria-label="copiar código de ativação"
                  icon={<Icon as={FiCopy} boxSize="20px" />}
                  color="primary.primary"
                  marginLeft={1}
                />
              </>
            )}
          </MenuItem>
        )}
        {!isActiveEmployee && (
          <MenuItem
            icon={<MailPlusIcon boxSize="16px" />}
            onClick={sendCommsConfirmationAlert.onOpen}
          >
            Reenviar comunicação
          </MenuItem>
        )}
        <MenuItem
          icon={<Icon as={FiEdit} boxSize="16px" />}
          onClick={() =>
            navigate(`/colaboradores/editar-colaborador/${employee.id}`)
          }
        >
          Editar colaborador
        </MenuItem>
        {showTrackingCard && (
          <TrackingCardModal
            employeeName={employee.name}
            cardId={employee.cardId}
          >
            <MenuItem icon={<TruckIcon width="16px" height="14px" />}>
              Rastrear cartão
            </MenuItem>
          </TrackingCardModal>
        )}

        {employee.cardStatus === 'ACTIVE' && (
          <MenuItem
            icon={<Icon as={FiSlash} boxSize="16px" />}
            onClick={blockCardConfirmationAlert.onOpen}
          >
            Bloquear cartão
          </MenuItem>
        )}
        {employee.cardStatus !== 'NOT_FOUND' &&
          employee.cardStatus !== 'INACTIVE' && (
            <MenuItem
              icon={<FiCreditCard size="16px" />}
              onClick={newCardModal.onOpen}
            >
              Trocar cartão (nova via)
            </MenuItem>
          )}

        <MenuItem
          icon={<Icon as={FiDollarSign} boxSize="16px" />}
          onClick={() => {
            if (employeeReceiveDailyBenefits) {
              rechargeCompetencyConfirmationAlert.onOpen();
            } else {
              navigate('/recargas/nova-recarga', {
                state: { selectedEmployees: [{ ...employee }] },
              });
            }
          }}
        >
          Recarregar benefícios
        </MenuItem>
        {shouldEnableRefund && (
          <MenuItem
            icon={<Icon as={WalletIcon} boxSize="16px" />}
            isDisabled={isLoading || employeeInfo?.isInMultiCompany}
            onClick={() => {
              navigate(`/colaboradores/estornar-saldo/${employee.id}`);
            }}
          >
            Estornar saldos
          </MenuItem>
        )}
        <MenuItem
          icon={<Icon as={FiPower} boxSize="16px" />}
          onClick={disableEmployeeConfirmationAlert.onOpen}
        >
          Desligar colaborador
        </MenuItem>

        {disableEmployeeConfirmationAlert.isOpen && (
          <AlertDialog
            size="2xl"
            isOpen={disableEmployeeConfirmationAlert.isOpen}
            onClose={disableEmployeeConfirmationAlert.onClose}
            cancelButtonAction={disableEmployeeConfirmationAlert.onClose}
            title={
              <Flex align={'center'} gap="2">
                <Icon as={FiPower} boxSize={'24px'} />
                Deseja realmente desligar {employeeName}?{' '}
              </Flex>
            }
            confirmButtonLabel="Desligar colaborador"
            isConfirmButtonLoading={disableEmployees.isPending}
            confirmButtonAction={() => {
              disableEmployees.mutate(
                { collaboratorIds: [employee.id] },
                {
                  onSuccess: () => {
                    disableEmployeeConfirmationAlert.onClose();
                  },
                }
              );
            }}
          >
            <DisableEmployeeCardDisplay
              disableEmployeeContent={disableSingleEmployeeHelperTexts}
            />
          </AlertDialog>
        )}

        {sendCommsConfirmationAlert.isOpen && (
          <AlertDialog
            isOpen={sendCommsConfirmationAlert.isOpen}
            onClose={sendCommsConfirmationAlert.onClose}
            cancelButtonAction={sendCommsConfirmationAlert.onClose}
            title={`Enviar comunicação para ${employeeName}`}
            confirmButtonLabel="Enviar"
            isConfirmButtonLoading={sendCommsToEmployees.isPending}
            confirmButtonAction={() =>
              sendCommsToEmployees.mutate(
                { collaboratorIds: [employee.id] },
                {
                  onSuccess: () => {
                    sendCommsConfirmationAlert.onClose();
                  },
                }
              )
            }
          >
            Enviaremos novamente ao colaborador o e-mail com link de primeiro
            acesso do aplicativo.
          </AlertDialog>
        )}

        {blockCardConfirmationAlert.isOpen && (
          <AlertDialog
            size="2xl"
            isOpen={blockCardConfirmationAlert.isOpen}
            onClose={blockCardConfirmationAlert.onClose}
            cancelButtonAction={blockCardConfirmationAlert.onClose}
            title={`Bloquear o cartão do colaborador ${employeeName}`}
            confirmButtonLabel="Bloquear"
            confirmButtonAction={() =>
              blockCard.mutate(
                { employeeDocument: employee.cpf },
                {
                  onSuccess: () => {
                    blockCardConfirmationAlert.onClose();
                  },
                }
              )
            }
            isConfirmButtonLoading={blockCard.isPending}
          >
            <Stack spacing={4}>
              <Text>
                Iremos{' '}
                <Semibold>bloquear o cartão físico temporariamente</Semibold>,
                mas o colaborador poderá efetuar o desbloqueio pelo aplicativo,
                se quiser.
              </Text>
              <Text>
                Se o colaborador tiver um <Semibold>cartão virtual</Semibold>,
                este cartão{' '}
                <Semibold>continuará funcionando normalmente</Semibold>.
              </Text>
            </Stack>
          </AlertDialog>
        )}
        {rechargeCompetencyConfirmationAlert.isOpen && (
          <RechargeCompetencyModal
            disclosureProps={rechargeCompetencyConfirmationAlert}
            employees={[employee]}
          />
        )}
        {newCardModal.isOpen && (
          <NewCardModal
            isOpen={newCardModal.isOpen}
            onClose={newCardModal.onClose}
            employee={employee}
          />
        )}
      </MenuList>
    </Menu>
  );
});

export default EmployeeActionMenu;
