import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFetchContext } from '../../hooks/useFetchContext';
import { z } from 'zod';
import { registerEmployeeFormSchema } from '../../types/schemas/employeeRegistrationSchema';
import { useToast } from '@chakra-ui/react';
import { useUserCompaniesStore } from '../../stores/useUserCompaniesStore';
import {
  Employee,
  EmployeeBalanceResponse,
  EmployeeDetails,
  EmployeeInfo,
  EmployeeProcessing,
  PostEmployeeReturn,
} from '../../types/employee';
import { EmployeeRefundFormValues } from '../../pages/RefundEmployeeBalancePage/types';
import { useNavigate } from 'react-router-dom';

export const useGetEmployees = () => {
  const { api } = useFetchContext();
  const { selectedCompany } = useUserCompaniesStore();

  const res = useQuery({
    queryKey: ['employees', { customerId: selectedCompany?.customerId }],
    queryFn: async () =>
      await api.get<Employee[]>(
        `/api/collaborators?customerId=${selectedCompany?.customerId}`
      ),
    gcTime: 5 * 60_000, // 5 minutes
    staleTime: 5 * 60_000, // 5 minutes
    enabled: !!selectedCompany?.customerId,
    meta: {
      errorMessage:
        'Não foi possível carregar os colaboradores. Tente novamente mais tarde.',
    },
  });

  return res;
};

export const useGetEmployeeInfo = (
  document: string,
  enabled: boolean | undefined = true
) => {
  const { api } = useFetchContext();
  const { selectedCompany } = useUserCompaniesStore();

  const res = useQuery({
    queryKey: [
      'employeInfo',
      { customerId: selectedCompany?.customerId },
      document,
    ],
    queryFn: async () =>
      await api.get<EmployeeInfo[]>(
        `/api/collaborators/info?customerId=${selectedCompany?.customerId}&document=${document}`
      ),
    gcTime: 5 * 60_000, // 5 minutes
    staleTime: 5 * 60_000, // 5 minutes
    enabled:
      !!selectedCompany?.customerId &&
      !!document &&
      document.length === 11 &&
      enabled,
    meta: {
      errorMessage:
        'Não foi possível carregar as informações do colaborador. Tente novamente mais tarde.',
    },
  });

  return res;
};

export const useGetProcessingEmployees = () => {
  const { api } = useFetchContext();
  const { selectedCompany } = useUserCompaniesStore();

  return useQuery({
    queryKey: [
      'processingEmployees',
      { customerId: selectedCompany?.customerId },
    ],
    queryFn: async () =>
      await api.get<EmployeeProcessing[]>(
        `/api/collaborators/processing?customerId=${selectedCompany?.customerId}`
      ),
    select: (response) => response.data,
    meta: {
      errorMessage:
        'Não foi possível carregar os colaboradores em processamento. Tente novamente mais tarde.',
    },
    gcTime: 5 * 60_000, // 5 minutes
    staleTime: 5 * 60_000, // 5 minutes
  });
};

type EmployeeCount = {
  processing: number;
};

export const useGetCountProcessingEmployees = () => {
  const { api } = useFetchContext();
  const { selectedCompany } = useUserCompaniesStore();

  return useQuery({
    queryKey: [
      'countProcessingEmployees',
      { customerId: selectedCompany?.customerId },
    ],
    queryFn: async () =>
      await api.get<EmployeeCount>(
        `/api/collaborators/count?customerId=${selectedCompany?.customerId}`
      ),
    select: (response) => response.data,
    meta: {
      errorMessage:
        'Não foi possível carregar a quantidade de colaboradores em processamento. Tente novamente mais tarde.',
    },
    enabled: !!selectedCompany?.customerId,
  });
};

export const useGetEmployeeById = (employeeId: string | undefined) => {
  const { api } = useFetchContext();
  return useQuery({
    queryKey: ['employee', { employeeId }],
    queryFn: () => api.get<EmployeeDetails>(`/api/collaborators/${employeeId}`),
    enabled: !!employeeId,
    meta: {
      errorMessage:
        'Não foi possível carregar os dados do colaborador. Tente novamente mais tarde.',
    },
  });
};

type UpdateEmployeePayload = Omit<
  EmployeeDetails,
  'companyTradeName' | 'document' | 'userStatus'
>;

export const useUpdateEmployee = () => {
  const { api } = useFetchContext();
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ employee }: { employee: UpdateEmployeePayload }) =>
      api.patch(`/api/collaborators/${employee.id}`, employee),
    onSuccess: (_response, variables) => {
      toast({
        title: 'Colaborador atualizado com sucesso!',
        status: 'success',
      });
      queryClient.invalidateQueries({
        queryKey: ['employee', { employeeId: variables.employee.id }],
      });

      if (variables.employee.status === 'PROCESSING') {
        queryClient.invalidateQueries({
          queryKey: ['processingEmployees'],
        });
      } else {
        queryClient.invalidateQueries({ queryKey: ['employees'] });
      }
    },
    onError: () => {
      toast({
        title: 'Tivemos um problema para atualizar o colaborador.',
        status: 'error',
        description: 'Tente de novo daqui a pouco.',
      });
    },
  });
};

type FormValues = z.infer<typeof registerEmployeeFormSchema>;
type PostProps = {
  onOpen: () => void;
};

export const usePostEmployee = (postProps: PostProps) => {
  const { api } = useFetchContext();
  const toast = useToast();
  const queryClient = useQueryClient();

  const res = useMutation({
    mutationFn: ({ employee }: { employee: FormValues }) =>
      api.post<PostEmployeeReturn>('/api/collaborators', employee),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['employees'] });
      queryClient.invalidateQueries({
        queryKey: ['processingEmployees'],
      });
      postProps.onOpen();
    },
    onError: () => {
      toast({
        title: 'Tivemos um problema para salvar o colaborador.',
        status: 'error',
        description: 'Tente de novo daqui a pouco.',
      });
    },
  });
  return res;
};
export const useEnableEmployee = () => {
  const { api } = useFetchContext();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { selectedCompany } = useUserCompaniesStore();

  const res = useMutation({
    mutationFn: ({ employeeId }: { employeeId: string }) =>
      api.patch(`/api/collaborators/${employeeId}/enable`),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['employees', { customerId: selectedCompany?.customerId }],
      });
    },
    onError: () => {
      toast({
        title: 'Tivemos um problema para habilitar o colaborador.',
        status: 'error',
        description: 'Tente de novo daqui a pouco.',
      });
    },
  });
  return res;
};

export const useSendCommsToEmployees = () => {
  const { api } = useFetchContext();
  const toast = useToast();
  const { selectedCompany } = useUserCompaniesStore();

  return useMutation({
    mutationFn: ({ collaboratorIds }: { collaboratorIds: string[] }) => {
      return api.post(
        `/api/collaborators/send-communication?collaboratorIds=${collaboratorIds}&companyId=${selectedCompany?.id}`
      );
    },

    onSuccess: (_response, variables) => {
      const { collaboratorIds } = variables;

      const title =
        collaboratorIds.length > 1
          ? 'Comunicação reenviada aos colaboradores!'
          : 'Comunicação reenviada ao colaborador!';

      toast({
        title: title,
        status: 'success',
      });
    },

    onError: () => {
      toast({
        title: 'Tivemos um problema para reenviar a comunicação.',
        status: 'error',
        description: 'Tente de novo daqui a pouco.',
      });
    },
  });
};

export const useDisableEmployees = () => {
  const { api } = useFetchContext();
  const toast = useToast();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({ collaboratorIds }: { collaboratorIds: string[] }) =>
      api.patch(`/api/collaborators/disable`, {
        collaboratorIds,
      }),

    onSuccess: (_response, variables) => {
      const { collaboratorIds } = variables;

      const title =
        collaboratorIds.length > 1
          ? 'Colaboradores desligados!'
          : 'Colaborador desligado!';

      toast({
        title: title,
        status: 'success',
      });
      queryClient.invalidateQueries({ queryKey: ['employees'] });
    },

    onError: (_response, variables) => {
      const { collaboratorIds } = variables;

      const title =
        collaboratorIds.length > 1
          ? 'Tivemos um problema para desligar os colaboradores.'
          : 'Tivemos um problema para desligar o colaborador.';

      toast({
        title: title,
        status: 'error',
        description: 'Tente de novo daqui a pouco.',
      });
    },
  });

  return mutation;
};

type BulkEmployeeBenefitPackagesMutationProps = {
  employeesIds: string[];
  packageId: string | null;
};

export const usePatchBulkEmployeeBenefitPackage = () => {
  const { api } = useFetchContext();
  const toast = useToast();

  const mutation = useMutation({
    mutationFn: ({
      employeesIds,
      packageId,
    }: BulkEmployeeBenefitPackagesMutationProps) =>
      api.patch(`/api/collaborators/update-package`, {
        employeesIds,
        packageId,
      }),

    onSuccess: (_response, variables) => {
      const { employeesIds } = variables;

      const title =
        employeesIds.length > 1
          ? `Definido com sucesso para ${employeesIds.length} colaboradores!`
          : 'Definido com sucesso para o colaborador selecionado!';

      toast({
        title: title,
        status: 'success',
      });
    },

    onError: (_response, variables) => {
      const { employeesIds } = variables;

      const title =
        employeesIds.length > 1
          ? 'Tivemos um problema para definir o pacote de benefícios para os colaboradores selecionados.'
          : 'Tivemos um problema para definir o pacote de benefícios para o colaborador selecionado.';

      toast({
        title: title,
        status: 'error',
        description: 'Tente de novo daqui a pouco.',
      });
    },
  });

  return mutation;
};

type GetEmployeesDocumentProps = {
  onlyActive?: boolean;
};

export const useGetEmployeesDocument = (props?: GetEmployeesDocumentProps) => {
  const { api } = useFetchContext();
  const { selectedCompany } = useUserCompaniesStore();

  const onlyActive = props?.onlyActive;

  const statusQuery = onlyActive ? `&status=ACTIVE` : '';

  return useQuery({
    queryKey: [
      'employeesDocument',
      { customerId: selectedCompany?.customerId },
    ],
    queryFn: async () =>
      await api.get<string[]>(
        `/api/collaborators/person/document?customerId=${selectedCompany?.customerId}${statusQuery}`
      ),
    select: (response) => response.data,
  });
};

export const useGetEmployeeBalance = (employeeId?: string) => {
  const { api } = useFetchContext();

  return useQuery({
    queryKey: ['employee-balance', { employeeId }],
    enabled: !!employeeId,
    staleTime: 0,
    gcTime: 0,
    queryFn: async () =>
      await api.get<EmployeeBalanceResponse>(
        `/api/collaborators/${employeeId}/account-balance`
      ),
    select: (response) => response.data,
    meta: {
      errorMessage:
        'Não foi possível o saldo do colaborador. Tente novamente mais tarde.',
    },
  });
};

type RefundEmployeeBalancePayload = EmployeeRefundFormValues & {
  collaboratorId: string;
};

export const useRefundEmployeeBalance = () => {
  const { api } = useFetchContext();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { selectedCompany } = useUserCompaniesStore();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: ({ payload }: { payload: RefundEmployeeBalancePayload }) => {
      const preparedPayload = {
        ...payload,
        companyId: selectedCompany?.id,
      };
      return api.post<PostEmployeeReturn>(
        '/api/collaborators/recover-account-balance',
        preparedPayload
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['employee-balance'] });
      toast({
        status: 'success',
        title: 'Estorno realizado com sucesso!',
      });
      navigate('/colaboradores');
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Não conseguimos realizar o estorno',
        description: 'Tente de novo daqui a pouco',
      });
    },
  });
};
