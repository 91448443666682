import { LuPlusCircle } from 'react-icons/lu';
import { Button, Card, CardProps, Flex, Icon, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { BiWallet } from 'react-icons/bi';

const CreditOrderEmptyState = (props: CardProps) => {
  const navigate = useNavigate();
  return (
    <Card
      width="100%"
      height="100%"
      direction="column"
      alignItems="center"
      justifyContent="center"
      gap={6}
      backgroundColor="gray.50"
      paddingY="160px"
      {...props}
    >
      <Icon
        as={BiWallet}
        width="49px"
        height="44px"
        viewBox="0 0 49 44"
        color="primary.primary"
      />
      <Flex alignItems="center" flexDirection="column" gap="3">
        <Text
          fontSize="32px"
          fontWeight={700}
          fontFamily="Sora"
          lineHeight="130%"
          textColor={'colors.black'}
        >
          Já Raiô!
        </Text>
        <Text
          fontSize="32px"
          fontFamily="Inter"
          lineHeight="130%"
          textColor={'colors.black'}
        >
          Você ainda não possui nenhuma movimentação
        </Text>
      </Flex>
      <Flex>
        <Button
          maxWidth="219px"
          leftIcon={<LuPlusCircle size="20px" />}
          onClick={() => navigate('/creditos-raio/pedido')}
        >
          Adicionar créditos
        </Button>
      </Flex>
    </Card>
  );
};

export default CreditOrderEmptyState;
