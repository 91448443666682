import {
  Box,
  Button,
  Card,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { BiWallet } from 'react-icons/bi';
import AlertCard from '../../../../components/AlertCard';
import { LuPlusCircle } from 'react-icons/lu';
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import SortDirectionIndicator from '../../../../components/SortDirectionIndicator';
import { defaultColumns } from './../../components/ColumnHelper';
import Pagination from '../../../../components/Pagination';
import CreditsInfoModalButton from '../../../../components/CreditsInfoModalButton';
import {
  CompanyAccountBalance,
  CreditTransaction,
} from '../../../../types/credits';
import { formatCurrencyInCents } from '../../../../utils/formatters/formatCurrency';
import { getPaginationRowModel } from '@tanstack/react-table';
import { useState } from 'react';
import { getSortedRowModel } from '@tanstack/react-table';
import ActionMenu from '../ActionMenu';
import { useNavigate } from 'react-router-dom';
import CreditOrderEmptyState from '../CreditOrderEmptyState';

type CreditOrderListProps = {
  accountBalance: CompanyAccountBalance | undefined;
  transactions: CreditTransaction[] | undefined;
};

const CreditOrderList = ({
  accountBalance = {
    accountBalanceAvailable: 0,
    accountBalancePendingSettlement: 0,
    accountBalanceTotal: 0,
  },
  transactions = [],
}: CreditOrderListProps) => {
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'transactionDateTime',
      desc: true,
    },
  ]);
  const table = useReactTable({
    data: transactions,
    columns: defaultColumns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting: sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const { accountBalanceTotal, accountBalancePendingSettlement } =
    accountBalance;

  const navigate = useNavigate();

  const totalRowsOnPage = table.getRowModel().rows.length;
  const totalRows = transactions.length;

  return (
    <Box>
      <Flex alignItems="start">
        <Flex gap={4} width="100%" flexWrap="wrap">
          <Flex
            gap={2}
            padding={4}
            background="white"
            borderRadius="8px"
            height="56px"
          >
            <BiWallet size="24px" />
            <Text fontSize="18px" fontWeight={600} color="gray.800">
              Valor disponível: {formatCurrencyInCents(accountBalanceTotal)}
            </Text>
          </Flex>

          {accountBalancePendingSettlement > 0 && (
            <AlertCard padding={6} height="56px" width="fit-content" gap={2}>
              <Text>
                Recargas programadas:{' '}
                <Text
                  as="span"
                  fontSize="18px"
                  fontWeight={600}
                  color="gray.800"
                >
                  {formatCurrencyInCents(accountBalancePendingSettlement)}
                </Text>
              </Text>
            </AlertCard>
          )}
          <CreditsInfoModalButton />
        </Flex>

        <Button
          maxWidth="219px"
          leftIcon={<LuPlusCircle size="20px" />}
          onClick={() => navigate('/creditos-raio/pedido')}
        >
          Adicionar créditos
        </Button>
      </Flex>

      <Flex direction="column" marginTop={10}>
        {transactions.length === 0 && <CreditOrderEmptyState />}
        {transactions.length > 0 && (
          <Card padding={6} maxWidth="100%" overflowX="auto">
            <Table>
              <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <Th
                          key={header.id}
                          cursor={
                            header.column.getCanSort() ? 'pointer' : 'unset'
                          }
                          onClick={header.column.getToggleSortingHandler()}
                          userSelect="none"
                        >
                          <Flex
                            alignItems="center"
                            gap={2}
                            {...header.column.columnDef.meta}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {header.column.getCanSort() && (
                              <SortDirectionIndicator
                                direction={header.column.getIsSorted()}
                              />
                            )}
                          </Flex>
                        </Th>
                      );
                    })}
                    <Th textAlign="center">Ações</Th>
                  </Tr>
                ))}
              </Thead>

              <Tbody>
                {table.getRowModel().rows.map((row) => (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}
                    <Td>
                      <ActionMenu transaction={row.original} />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Card>
        )}
      </Flex>

      {transactions.length > 0 && (
        <Flex justifyContent="space-between" alignItems="center" marginTop={4}>
          <Pagination
            currentPage={table.getState().pagination.pageIndex + 1}
            totalPages={table.getPageCount()}
            onChangeRowsPerPage={(option) =>
              table.setPageSize(option?.value ?? 10)
            }
            handleClickFirstPage={() => table.setPageIndex(0)}
            handleClickNextPage={() => table.nextPage()}
            handleClickPreviousPage={() => table.previousPage()}
            handleClickLastPage={() =>
              table.setPageIndex(table.getPageCount() - 1)
            }
            elementsOnPage={totalRowsOnPage}
            totalElements={totalRows}
          />
        </Flex>
      )}
    </Box>
  );
};

export default CreditOrderList;
