import { Flex, FlexProps, Icon, Text } from '@chakra-ui/react';
import { FiCheckCircle, FiClock } from 'react-icons/fi';

type RechargeInfoPageTitleProps = FlexProps & {
  isLoaded: boolean;
};

const RechargeInfoPageTitle = ({ isLoaded }: RechargeInfoPageTitleProps) => {
  if (isLoaded) {
    return (
      <Flex alignItems="center" marginBottom={4}>
        <Icon as={FiCheckCircle} boxSize="32px" color="feedback.success" />
        <Text fontSize="20px" fontWeight={500} ml={2}>
          Recarga criada com sucesso!
        </Text>
      </Flex>
    );
  } else {
    return (
      <Flex alignItems="center" marginBottom={4}>
        <Icon as={FiClock} boxSize="32px" color="status.loading" />
        <Text fontSize="20px" fontWeight={500} ml={2}>
          Gerando documentos da recarga
        </Text>
      </Flex>
    );
  }
};

export default RechargeInfoPageTitle;
