import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Text,
} from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import {
  EditRechargeResultResponse,
  RechargeStatusEnum,
} from '../../../types/recharge';
import { FiInfo } from 'react-icons/fi';
import { useMemo } from 'react';
import { Employee } from '../../../types/employee';
import ContactModalButton from '../../../components/ContactModalButton';
import { H2 } from '../../../components/Typography';
import { getRechargeVisibilityLabel } from '../../NewRechargePage/utils';
import EditedRechargeInfoPix from './EditedRechargeInfoPix';
import EditedRechargeDocs from './EditedRechargeDocs';
import EditedRechargeInfoBoleto from './EditedRechargeInfoBoleto';
import EditedRechargeInfoAccountBallance from './EditedRechargeAccountBalanceInfo';

type LocationResultResponse = EditRechargeResultResponse & {
  changeDueDate?: boolean;
  isEdit?: boolean;
  status?: RechargeStatusEnum;
  observation?: string;
};

const RechargeVisibilityConfig = ({
  text,
  height,
}: {
  text: string;
  height?: string;
}) => {
  return (
    <Box
      padding="32px"
      background="white"
      borderRadius="8px"
      marginTop="32px"
      flexDirection="column"
      display="flex"
      height={height}
    >
      <Text fontSize="20px" fontWeight={500}>
        Exibição no aplicativo
      </Text>
      <Box
        background="gray.100"
        padding="12px"
        display="flex"
        alignItems="center"
        gap={2}
        marginTop="32px"
        borderRadius="4px"
      >
        <Box>
          <FiInfo size="20px" />
        </Box>
        <Text>{text}</Text>
      </Box>
    </Box>
  );
};

const EditedRechargeInfo = () => {
  const location = useLocation();

  const rechargeInfo = location.state as LocationResultResponse | undefined;

  const statusIsProcessed: boolean =
    rechargeInfo?.status === RechargeStatusEnum.PROCESSED;

  const isPluralRecharge = useMemo(() => {
    const categoryUsage: string[] = [];

    if (location?.state?.employees) {
      location?.state?.employees.forEach((employee: Employee) => {
        employee.categories.forEach((category) => {
          if (category.value > 0) {
            categoryUsage.push(category.id);
            if (categoryUsage.length > 1) return;
          }
        });
      });

      return categoryUsage.length > 1 ? true : false;
    }

    return false;
  }, [location?.state?.employees]);

  const linesArray = rechargeInfo?.observation
    ? rechargeInfo?.observation.split('\n')
    : [];

  if (!rechargeInfo) {
    return (
      <Alert
        status="error"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="200px"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          Ops! Não foi mostrar possível informações sobre a recarga.
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          Entre em contato com o nosso suporte. <ContactModalButton />
        </AlertDescription>
      </Alert>
    );
  }
  return (
    <Box height="100%" width="100%">
      <Breadcrumb marginBottom={'40px'}>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/">
            Início
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/recargas">
            Recargas
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink>Informações da recarga</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex
        justifyContent="space-between"
        width="100%"
        flexDir={'column'}
        alignItems={'flex-start'}
        height="100%"
        paddingBottom={8}
      >
        <Flex
          flexWrap={{ sm: 'wrap', md: 'wrap' }}
          flexDirection={'row'}
          gap={{ base: '0', xl: '8' }}
          alignItems={'flex-start'}
          height="100%"
          width="100%"
        >
          <Box
            background={!statusIsProcessed ? 'white' : 'unset'}
            borderRadius="8px"
            height={{
              base: 'auto',
              xl: '100%',
            }}
            width={{
              base: '100%',
              xl: '67%',
            }}
          >
            {rechargeInfo.paymentMethod === 'BOLETO' && (
              <EditedRechargeInfoBoleto
                rechargeInfo={rechargeInfo}
                statusIsProcessed={statusIsProcessed}
              />
            )}
            {rechargeInfo.paymentMethod === 'PIX' && (
              <EditedRechargeInfoPix
                rechargeInfo={rechargeInfo}
                statusIsProcessed={statusIsProcessed}
              />
            )}
            {rechargeInfo.paymentMethod === 'ACCOUNT_BALANCE' && (
              <EditedRechargeInfoAccountBallance
                rechargeInfo={rechargeInfo}
                statusIsProcessed={statusIsProcessed}
              />
            )}

            {!rechargeInfo?.isEdit && (
              <Text paddingX={8} color="primary.primary">
                <Text as="span" fontWeight={600}>
                  Você pode editar
                </Text>{' '}
                a forma de pagamento, a data de crédito dos benefícios e a
                configuração de exibição no aplicativo{' '}
                <Text as="span" fontWeight={600}>
                  através do menu Recargas
                </Text>
              </Text>
            )}
            {statusIsProcessed && (
              <RechargeVisibilityConfig
                text={getRechargeVisibilityLabel(
                  rechargeInfo?.rechargeVisibilityConfig
                )}
              />
            )}
          </Box>

          <Flex
            width={{
              base: '100%',
              xl: '30%',
            }}
            height={{ xl: '100%' }}
            flexDirection={'column'}
          >
            <EditedRechargeDocs
              rechargeInfo={rechargeInfo}
              isPluralRecharge={isPluralRecharge}
              statusIsProcessed={statusIsProcessed}
            />
            {rechargeInfo?.observation && (
              <Box padding={8} bg="white" borderRadius="8px" marginTop={8}>
                <H2 marginBottom={8}>
                  {isPluralRecharge
                    ? 'Observações das notas fiscais'
                    : 'Observações da nota fiscal'}
                </H2>
                <Box
                  padding={4}
                  borderRadius="8px"
                  backgroundColor="gray.100"
                  marginBottom={4}
                >
                  {linesArray.map((line, index) => (
                    <Text key={index} minHeight={4} maxWidth="75vw">
                      {line}
                    </Text>
                  ))}
                </Box>
              </Box>
            )}
            {!statusIsProcessed && (
              <RechargeVisibilityConfig
                height="100%"
                text={getRechargeVisibilityLabel(
                  rechargeInfo?.rechargeVisibilityConfig
                )}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default EditedRechargeInfo;
