import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Link,
  Skeleton,
  Text,
  forwardRef,
} from '@chakra-ui/react';
import { H2 } from '../../../../components/Typography';
import { FiDownload } from 'react-icons/fi';
import { format } from 'date-fns';
import {
  DocumentTypeEnum,
  RechargePaymentMethod,
  RechargePollingResponse,
} from '../../../../types/recharge';
import { baseDownloadUrl } from '../../../../constants/general';
import InvoiceDownloadButton from '../InvoiceDownloadButton';

export const DownloadButton = forwardRef<ButtonProps, 'button'>(
  (props, ref) => {
    return (
      <Button
        ref={ref}
        leftIcon={<FiDownload />}
        paddingY={2}
        paddingX={4}
        background={'gray.100'}
        variant={'ghost'}
        fontSize={'16px'}
        fontWeight={600}
        _disabled={{
          color: 'gray.300',
          _hover: {
            background: 'gray.100',
          },
        }}
        {...props}
      />
    );
  }
);

type RechargeDocsProps = {
  recharge: RechargePollingResponse | undefined;
  isPluralRecharge: boolean;
  statusIsProcessed: boolean;
};

const RechargeDocs = ({
  recharge,
  isPluralRecharge,
  statusIsProcessed,
}: RechargeDocsProps) => {
  const paymentMethodDueDate = format(
    new Date(recharge?.rechargeInfo?.invoice?.dueDate || new Date()),
    'dd/MM/yyyy'
  );
  const isRechargePaidWithAccountBalance =
    recharge?.rechargeInfo?.paymentMethod ===
    RechargePaymentMethod.ACCOUNT_BALANCE;

  return (
    <Box p={8} bg="white" borderRadius="8px" width="100%">
      <H2>Documentos da recarga</H2>
      {!statusIsProcessed && (
        <Text mt={9}>
          Se quiser, você pode fazer{' '}
          <Text as="span" fontWeight={600}>
            download{' '}
          </Text>
          dos documentos da recarga.
          <Text marginTop={4} as="span" display="block">
            Eles também serão enviados{' '}
            <Text as="span" fontWeight={600}>
              para você e para o time financeiro por e-mail.
            </Text>
          </Text>
        </Text>
      )}
      <Flex flexDir={'column'} gap={4} marginTop={8}>
        <Skeleton isLoaded={!!recharge?.rechargeInfo}>
          {!isRechargePaidWithAccountBalance && !statusIsProcessed && (
            <DownloadButton
              isDisabled={!recharge?.rechargeId}
              maxWidth="fit-content"
              as={Link}
              href={`${baseDownloadUrl}?rechargeId=${recharge?.rechargeId}&documentType=${DocumentTypeEnum.PAYMENT}`}
              download={`Recarga Raiô - Vencimento ${paymentMethodDueDate}.pdf`}
              height="56px"
            >
              {recharge?.rechargeInfo?.paymentMethod === 'BOLETO' && 'Boleto'}
              {recharge?.rechargeInfo?.paymentMethod === 'PIX' && 'Código QR'}
            </DownloadButton>
          )}
        </Skeleton>

        <Skeleton isLoaded={!!recharge?.rechargeInfo}>
          <DownloadButton
            as={Link}
            height="56px"
            isDisabled={!recharge?.rechargeId}
            href={`${baseDownloadUrl}?rechargeId=${recharge?.rechargeId}&documentType=${DocumentTypeEnum.REPORT}`}
          >
            Relatório da recarga
          </DownloadButton>
        </Skeleton>

        <Skeleton isLoaded={!!recharge?.rechargeInfo}>
          <InvoiceDownloadButton
            isPluralRecharge={isPluralRecharge}
            rechargeId={recharge?.rechargeId}
            fetchInvoice={!!recharge?.rechargeInfo}
          />
        </Skeleton>
      </Flex>
    </Box>
  );
};

export default RechargeDocs;
