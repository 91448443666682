import {
  Box,
  Flex,
  CheckboxGroup,
  useCheckboxGroup,
  Text,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import React from 'react';
import CheckboxCard from '../../../../components/CheckboxCard';
import { benefitPackageSchema } from '../../../../types/schemas/employeeRegistrationSchema';
import { z } from 'zod';
import ConnectForm from '../../../../components/ConnectForm';
import { BenefitPackage } from '../../../../types/benefit';
import BenefitCard from '../../../../components/BenefitCard';
import { LuPlusCircle } from 'react-icons/lu';
import CreateAndEditBenefitPackage from '../../../BenefitsPage/components/CreateAndEditBenefitPackage';
import { useGetSelectedCompanyBenefitCategories } from '../../../../api/benefit';

type FormValues = z.infer<typeof benefitPackageSchema>;

const BenefitsForm = ({ packages }: { packages: BenefitPackage[] }) => {
  const createBenefitDrawerProps = useDisclosure();

  const { data: companyBenefitCategories } =
    useGetSelectedCompanyBenefitCategories({
      onlyActive: true,
    });

  const {
    value: selectedOptions,
    setValue: setCheckboxValue,
    getCheckboxProps,
  } = useCheckboxGroup();

  return (
    <>
      <ConnectForm<FormValues>>
        {({ setValue, getValues }) => {
          const benefitPackageId = getValues('packageId');

          // custom function to allow only one checkbox to be checked at a time
          function handleCheckboxChange(
            e: React.ChangeEvent<HTMLInputElement>
          ) {
            const nextValue = e.target.value;
            if (selectedOptions.includes(nextValue)) {
              setCheckboxValue([]);
              setValue('packageId', undefined);
              return;
            }
            const checked = e.target.checked;

            if (checked) {
              setCheckboxValue([nextValue]);
              setValue('packageId', nextValue);
            }
          }

          return (
            <Box margin={4}>
              <Flex gap={6} flexWrap="wrap" height="100%">
                <CheckboxGroup>
                  {packages.length > 0 &&
                    packages.map((benefit) => {
                      const isChecked = benefitPackageId === benefit.id;
                      return (
                        <CheckboxCard
                          key={benefit.id}
                          {...getCheckboxProps({ value: benefit.id })}
                          onChange={handleCheckboxChange}
                          isChecked={isChecked}
                          height="100%"
                          boxHeight="auto"
                          minWidth="288px"
                        >
                          <BenefitCard benefit={benefit} />
                        </CheckboxCard>
                      );
                    })}
                </CheckboxGroup>
                <Button
                  variant="ghost"
                  cursor="pointer"
                  border="1.5px solid"
                  borderColor="primary.primary"
                  borderRadius="8px"
                  width="292px"
                  minHeight="292px"
                  height="auto"
                  padding={6}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  gap={6}
                  color="primary.primary"
                  onClick={createBenefitDrawerProps.onOpen}
                >
                  <LuPlusCircle size="40px" color="currentColor" />
                  <Text
                    fontSize="16px"
                    fontWeight={600}
                    textAlign="center"
                    paddingX={6}
                    whiteSpace="normal"
                  >
                    Montar novo pacote de benefícios
                  </Text>
                </Button>
              </Flex>
            </Box>
          );
        }}
      </ConnectForm>
      <CreateAndEditBenefitPackage
        isOpen={createBenefitDrawerProps.isOpen}
        onClose={createBenefitDrawerProps.onClose}
        companyBenefitCategories={companyBenefitCategories}
      />
    </>
  );
};

export default BenefitsForm;
