import { Box, Card, Flex, Icon, Skeleton, Text } from '@chakra-ui/react';
import { formatCurrencyInCents } from '../../../../utils/formatters/formatCurrency';
import { FiCalendar, FiCheckCircle, FiClock } from 'react-icons/fi';
import { RechargePollingResponse } from '../../../../types/recharge';
import { WalletIcon } from '../../../../assets/customIcons/WalletIcon';
import { capitalizeFirstLetter } from '../../../../utils/formatters/capitalizeFirstLetter';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

type RechargeInfoAccountBalanceProps = {
  recharge: RechargePollingResponse | undefined;
};

const RechargeInfoAccountBallance = ({
  recharge,
}: RechargeInfoAccountBalanceProps) => {
  const rechargeInfo = recharge?.rechargeInfo;

  return (
    <>
      <Flex
        padding={8}
        flexDirection={'column'}
        alignItems="left"
        width="100%"
        gap={6}
        flexWrap={'wrap'}
        background="white"
      >
        {recharge?.rechargeInfo ? (
          <Flex alignItems="center">
            <Icon as={FiCheckCircle} boxSize="32px" color="feedback.success" />
            <Text fontSize="20px" fontWeight={500} ml={2}>
              Recarga paga com sucesso com Créditos Raiô!
            </Text>
          </Flex>
        ) : (
          <Flex alignItems="center">
            <Icon as={FiClock} boxSize="32px" color="status.loading" />
            <Text fontSize="20px" fontWeight={500} ml={2}>
              Gerando documentos da recarga
            </Text>
          </Flex>
        )}

        <Text fontSize="32px" fontWeight={600}>
          {rechargeInfo?.amount && formatCurrencyInCents(rechargeInfo?.amount)}
        </Text>

        <Flex flexDir={'column'}>
          <Text fontSize={'16px'} fontWeight={'600'} marginBottom={2}>
            Saldo atual
          </Text>
          <Skeleton isLoaded={!!rechargeInfo}>
            <Card
              height={'50px'}
              display={'flex'}
              padding={'16px'}
              alignContent={'flex-start'}
              justifyContent={'center'}
              background={'gray.100'}
            >
              <Box
                alignItems="center"
                display={'flex'}
                flexDir={'row'}
                justifyContent={'left'}
              >
                <Icon
                  as={WalletIcon}
                  boxSize="20px"
                  strokeWidth={2}
                  color="primary.primary"
                />
                <Text
                  fontSize={'18px'}
                  fontWeight="600"
                  lineHeight="130%"
                  ml="12px"
                  color={'gray.black'}
                >
                  Créditos Raiô:{' '}
                  {formatCurrencyInCents(
                    rechargeInfo?.accountBalance?.accountBalanceAvailable || 0
                  )}{' '}
                </Text>
              </Box>
            </Card>
          </Skeleton>
        </Flex>
        <Flex flexDir={'column'}>
          <Text fontSize={'16px'} fontWeight={'600'} marginBottom={4}>
            Data de crédito prevista
          </Text>
          <Skeleton isLoaded={!!rechargeInfo}>
            <Card
              height={'50px'}
              display={'flex'}
              padding={'16px'}
              alignContent={'flex-start'}
              justifyContent={'center'}
              background={'gray.100'}
            >
              <Box
                display={'flex'}
                flexDir={'row'}
                justifyContent={'left'}
                alignItems={'center'}
              >
                <Icon
                  as={FiCalendar}
                  boxSize="20px"
                  color="orange.500"
                  mr={2}
                  strokeWidth={2}
                />

                <Text
                  fontSize={'18px'}
                  fontWeight={'400'}
                  lineHeight={'130%'}
                  color={'gray.black'}
                >
                  {capitalizeFirstLetter(
                    format(
                      new Date(rechargeInfo?.scheduleDate || new Date()),
                      'eeee',
                      {
                        locale: ptBR,
                      }
                    )
                  ) + ', '}
                </Text>
                <Text
                  fontSize={'18px'}
                  fontWeight={'600'}
                  ml={1}
                  color="gray.black"
                >
                  {format(
                    new Date(rechargeInfo?.scheduleDate || new Date()),
                    'dd/MM/yyyy'
                  )}
                </Text>
              </Box>
            </Card>
          </Skeleton>
        </Flex>
      </Flex>
    </>
  );
};

export default RechargeInfoAccountBallance;
