import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link as ChakraLink,
  Text,
  VStack,
  VisuallyHidden,
  useToast,
  Flex,
  Heading,
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import { z } from 'zod';
import Title from '../../components/Title';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useFetchContext } from '../../hooks/useFetchContext';
import AccessLayout from '../../layouts/AccessLayout';
import PasswordInput from '../../components/PasswordInput';
import { useUserCompaniesStore } from '../../stores/useUserCompaniesStore';
import ContactModalButton from '../../components/ContactModalButton';
import { AuthState } from '../../types/auth';
import { HourglassIcon } from '../../assets/customIcons/HourglassIcon';

const FIELD_REQUIRED_MESSAGE = 'Você precisa preencher este campo.';

const schema = z.object({
  username: z
    .string({ required_error: FIELD_REQUIRED_MESSAGE })
    .email('E-mail inválido'),
  password: z
    .string({ required_error: FIELD_REQUIRED_MESSAGE })
    .min(1, { message: FIELD_REQUIRED_MESSAGE }),
});

type FormValues = z.infer<typeof schema>;

const LoginPage = () => {
  const { handleSubmit, formState, register, setError, watch } =
    useForm<FormValues>({
      resolver: zodResolver(schema),
    });
  const { errors } = formState;

  const { api } = useFetchContext();
  const { setAuthInfo, isAuthenticated } = useAuthContext();

  const { setSelectedCompany } = useUserCompaniesStore();

  const toast = useToast();

  const signIn = useMutation({
    mutationFn: (values: FormValues) =>
      api.post<AuthState>('/api/auth/login', values),
    onSuccess: (data) => {
      const user = data.data.user;
      setSelectedCompany(user?.selectedCompany);
    },
    onError: (error: AxiosError) => {
      const status = error.response?.status;
      if (status === 404 || status === 401) {
        setError('username', { message: '' });
        setError('password', { message: '' });
      } else if (status === 429) {
        toast({
          title: 'Limite de tentativas excedido',
          description:
            'Revise sua senha e aguarde alguns instantes para tentar novamente',
          status: 'error',
        });
      } else {
        toast({
          title: 'Erro ao fazer login',
          description: 'Tente novamente mais tarde.',
          status: 'error',
        });
      }
    },
  });

  const email = watch('username');

  const authInfo = signIn.data?.data;
  const isAuthed = isAuthenticated();

  const onSubmit = (values: FormValues) => {
    signIn.mutate(values);
  };

  useEffect(() => {
    if (authInfo) {
      setAuthInfo(authInfo);
    }
  }, [authInfo, setAuthInfo]);

  if (isAuthed) {
    return <Navigate to="/" />;
  }

  return (
    <AccessLayout variant="login">
      <Box
        paddingX={{
          base: '20px',
          md: '80px',
        }}
      >
        <Card
          backgroundColor="gray.50"
          width={{
            base: '100%',
            md: '566px',
          }}
          boxShadow="none"
          position="relative"
          minWidth="327px"
        >
          <HourglassIcon
            position="absolute"
            top="-40px"
            right="-40px"
            display={{
              base: 'none',
              md: 'block',
            }}
          />

          <CardHeader paddingBottom={8}>
            <Heading fontWeight={600} fontSize="24px">
              Entre na sua conta
            </Heading>
            <ErrorMessage
              errors={errors}
              name={errors.username ? 'username' : 'password'}
              render={() => (
                <Text
                  width="100%"
                  textAlign="left"
                  color="feedback.alert"
                  marginTop={4}
                  marginBottom={-3}
                >
                  Parece que seu e-mail ou senha estão incorretos.
                </Text>
              )}
            />
          </CardHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardBody paddingTop={0}>
              <VStack spacing={6}>
                <FormControl isInvalid={!!errors.username}>
                  <VisuallyHidden>
                    <FormLabel>Email</FormLabel>
                  </VisuallyHidden>
                  <FormLabel fontSize="14px">Qual o seu e-mail?</FormLabel>
                  <Input
                    type="username"
                    autoComplete="username"
                    placeholder="Digite o seu e-mail"
                    {...register('username')}
                  />
                  <FormErrorMessage>
                    <ErrorMessage name="username" errors={errors} />
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.password}>
                  <VisuallyHidden>
                    <FormLabel>Senha</FormLabel>
                  </VisuallyHidden>
                  <FormLabel fontSize="14px">Qual a sua senha?</FormLabel>
                  <PasswordInput {...register('password')} />

                  <FormErrorMessage>
                    <ErrorMessage name="password" errors={errors} />
                  </FormErrorMessage>
                </FormControl>

                <Flex
                  width="100%"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Text as="span" fontSize="14px" marginTop={-4}>
                    <ChakraLink
                      as={ReactRouterLink}
                      to="/solicitar-troca-de-senha"
                      state={{ emailFromLoginPage: email }}
                      textDecor="underline"
                      fontWeight={500}
                    >
                      Esqueci a senha
                    </ChakraLink>
                  </Text>
                </Flex>

                <Button
                  type="submit"
                  width="100%"
                  isLoading={signIn.isPending}
                  marginTop={2}
                >
                  Entrar na conta
                </Button>
              </VStack>
            </CardBody>
          </form>
        </Card>
        <Text textAlign="center" marginTop={4}>
          Ainda não tem cadastro? <ContactModalButton />
          <Text as="span" color="primary.primary"></Text>
        </Text>
      </Box>
    </AccessLayout>
  );
};

export default LoginPage;
