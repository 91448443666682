import { ReactNode } from 'react';
import CustomTooltip from '../../../../components/CustomTooltip';
import { Box, Link, LinkProps, useToast } from '@chakra-ui/react';

type CustomDownloadLinkProps = LinkProps & {
  href?: string;
  children: ReactNode;
  toolTipText?: string;
  isPending?: boolean;
};

const CustomDownloadLink = ({
  href,
  children,
  toolTipText,
  isPending,
  ...linkProps
}: CustomDownloadLinkProps) => {
  const toast = useToast();

  return (
    <CustomTooltip label={toolTipText ?? ''}>
      <Box
        as={Link}
        // only add href if it is defined
        {...(href && !isPending ? { href } : {})}
        download={true}
        boxSize="32px"
        borderRadius={4}
        padding={2}
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundColor={isPending ? 'light.pink' : 'gray.100'}
        _hover={
          isPending
            ? {
                backgroundColor: 'pink.100',
                color: 'pink.700',
              }
            : {
                backgroundColor: 'orange.50',
                color: 'orange.500',
              }
        }
        color={isPending ? 'status.loading' : 'gray.800'}
        cursor="pointer"
        onClick={() => {
          if (href && !isPending) {
            toast({
              title: 'Pedido de download criado com sucesso!',
              status: 'success',
            });
          }
        }}
        {...linkProps}
      >
        {children}
      </Box>
    </CustomTooltip>
  );
};

export default CustomDownloadLink;
