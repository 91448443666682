import { Flex } from '@chakra-ui/react';
import { useUserCompaniesStore } from '../../../../stores/useUserCompaniesStore';
import DoctorOneProductCard from '../DoctorOneProductCard';
import { useQuery } from '@tanstack/react-query';
import { useFetchContext } from '../../../../hooks/useFetchContext';

const ProductList = () => {
  const { selectedCompany } = useUserCompaniesStore();

  const companySvas = selectedCompany?.svas || {};

  const companyProducts = companySvas.products || [];

  const { api } = useFetchContext();

  const { data } = useQuery({
    queryKey: ['company-products', selectedCompany?.id],
    queryFn: () =>
      api.get(`api/companies/products?customerId=${selectedCompany?.id}`),
    enabled: false,
  });

  return (
    <Flex>
      {companyProducts.includes('DoutorOne') && (
        <DoctorOneProductCard
          totalValue={companySvas.totalValue}
          defaultBillingDay={companySvas?.defaultBillingDay}
        />
      )}
    </Flex>
  );
};

export default ProductList;
