import { PiBarcode } from 'react-icons/pi';
import CustomDownloadLink from '../CustomDownloadLink';
import { PixIcon } from '../../../../assets/customIcons/PixIcon';
import {
  RechargeFiles,
  RechargeListInfo,
  RechargePaymentMethod,
  RechargeStatusEnum,
} from '../../../../types/recharge';

interface RechargePaymentMethodsButtonsProps {
  recharge: RechargeListInfo;
  rechargeFiles: RechargeFiles | undefined;
  onOpen: () => void;
  paymentDocumentHref: string;
  isInternalProcessing: boolean;
}

const RechargePaymentMethodsButtons = ({
  recharge,
  rechargeFiles,
  onOpen,
  paymentDocumentHref,
  isInternalProcessing,
}: RechargePaymentMethodsButtonsProps) => {
  if (!rechargeFiles?.paymentFilePath) {
    return null;
  }

  const { PENDING, INTERNAL_PROCESSING } = RechargeStatusEnum;

  if (![PENDING, INTERNAL_PROCESSING].includes(recharge.status)) {
    return null;
  }

  return (
    <>
      {rechargeFiles.paymentType === RechargePaymentMethod.BOLETO && (
        <CustomDownloadLink
          isPending={isInternalProcessing}
          href={paymentDocumentHref}
          toolTipText={
            isInternalProcessing ? 'Boleto em processamento' : 'Boleto'
          }
        >
          <PiBarcode size="20px" color="fillCurrent" />
        </CustomDownloadLink>
      )}
      {rechargeFiles.paymentType === RechargePaymentMethod.PIX && (
        <CustomDownloadLink
          isPending={isInternalProcessing}
          toolTipText={
            isInternalProcessing ? 'Pix em processamento' : 'Pix Copia e Cola'
          }
          onClick={() => !isInternalProcessing && onOpen()}
        >
          <PixIcon />
        </CustomDownloadLink>
      )}
    </>
  );
};

export default RechargePaymentMethodsButtons;
